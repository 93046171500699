//Berater-Suche
@import "stepC.agency.fragment.less";

.visColumn {
  padding: 0 !important;
}

.sapUiCalHead > button, .sapUiCalCancel {
  background-color: lightgrey !important;
}

//.sapMBtnText>.sapMBtnIcon {
//  position: absolute;
//  top: 8px !important;
//  left: 8px !important;
//}

#visBody {
  button {
    background-color: transparent;
    color: inherit;
    margin: 0;
    font-size: 1.125rem;
    padding: 0;
    border-radius: 0;
    &:hover, &:active, &:focus {
      box-shadow: none;
    }
  }

  *, *:after, *:before{
    //box-sizing: inherit;
  }

  p:before, p:after,
  span:before, span:after,
  table tr th:before,
  table tr th:after,
  table tr td:before,
  table tr td:after {
    // content: inherit;
    //display: inherit;
  }

  p:before, p:after,
  span:not([class]):before, span:not([class]):after{
    content: initial;
  }

  &:not(.noEmbeded) {
    section {
      &:not(.sapMPageEnableScrolling) {
        overflow: visible !important;
      }
    }
  }

  .sapUiIcon {
    &:after {
      //content: initial;
      //display: inherit;
    }
    &:before {
      display: initial;
    }
  }

  input[type="search"] {
    border: none;
    box-shadow: none;
    font-size: 0.875rem;
    background: transparent;
    width: 100%;
    height: 2.375rem;
    outline: none;
    padding: 0;
    margin: 0;
    text-indent: 0.125rem;
    vertical-align: top;
    text-overflow: ellipsis;
    &:hover, &:active, &:focus {
      border: none !important;
      text-shadow: none !important;
    }
  }
  .oevbsIco, .sapMSltArrow, .fo-like  {
    &:before {
      display: inline-block;
    }
    &:after {
      display: none;
      content:"";
    }
  }
  @media (min-width: 880px) {
    .dataFrgmt {
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
}
@import "variables";
@import "shake.less";
@import "oevbs.styleguide.less";
@import "overwrites.oevbs.less";
@import "overwrites.blocklayout.less";
@import "overwrites.popover.less";
@import "overwrites.mobile.less";
@import "helpdesk.fragment.less";
@import "stepC.contactData.fragment.less";
@import "stepD.overview.fragment.less";
@import "stepD.consultation.fragment.less";
@import "stepD.completion.fragment.less";
@import "stepD.send.fragment.less";
@import "stepD.thanks.fragment.less";
@import "fonts";
@import "messages";
@import "roadmap";
@import "maintenance";
@import "overwrites.controls.bit.AutoComplete.less";
@import "offerView";

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

#visBody {
  .body;
  &.sapUiBody {
    .body;
  }

  min-height: 500px;

  &.loading {
    #content {
      visibility: hidden;
    }
    #init {
      display: block;
      .headline;
      span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        &:after, &:before {
          display: none;
          content:"";
        }
      }

      span:nth-child(2) {
        animation-delay: .2s;
      }

      span:nth-child(3) {
        animation-delay: .4s;
      }
    }
  }
  #init {
    display: none;
  }

  span, table {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  .sapUiBlockLayoutCell {
    &.wHL {
      h2 {
        .headline !important;
      }
    }
  }

  .sapMFT {
    .wHL {
      h3 {
        .headline;
      }
    }
  }

  .fo-question {
    cursor: help !important;
  }

  .curr {
    font-size: 1.25rem;
    &:after {
      content: ' €';
      display: inline;
    }
  }
  .fleft {
    float: left;
  }
  .fright {
    float: right;
  }
  .dIF {
    display: inline-flex !important;
  }

  .version {
    display: block;
    text-align: center;
    width: 100%;
    color: @color_greyblack;
    font-size: 1rem;
  }
  .w100 {
    width: 100% !important;
  }
  .h100 {
    height: 100% !important;
  }

  @iterations: 40;
  .wrem-loop (@i) when (@i > 0) {
    .w@{i}rem {
      width: ~"@{i}rem !important";
    }
    .wrem-loop(@i - 1);
  }
  .wrem-loop (@iterations);

  .flexGrow {
    flex-grow: 1;
  }

  .mr05rem {
    margin-right: 0.5rem;
  }
  .mr1rem {
    margin-right: 1rem;
  }

  .mb1rem {
    margin-bottom: 1rem;
  }

  .noBorder {
    border: none !important;
  }

  .sapUiLocalBusyIndicator,
  .sapUiBusyIndicator,
  .sapUiBLy {
    z-index: 200 !important;
  }

  .sapMDialogPhone {
    z-index: 220 !important;
  }

  .sapUiBLy {
    background-color: #eee !important;
  }

  .sap-ui-blocklayer-popup {
    z-index: 199 !important;
    background-color: transparent;
  }
  #sapUiBusyIndicator,
  .sapUiLocalBusy {
    z-index: 201 !important;
    .sapUiLocalBusyIndicator {
      z-index: 201 !important;
      .sapUiLocalBusyIndicatorAnimation {
        background: url('../img/loading-oevbs-s.gif') no-repeat;
        background-size: 100% 100%;
        width: 50px !important;
        height: 50px !important;
        left: ~"calc(100% / 2 - 25px)" !important;
        top: ~"calc(100% / 2 - 25px)" !important;
        z-index: 201 !important;
        position: fixed;
        > div {
          display: none !important;
        }
      }
    }
  }

  .btnMR {
    .sapMBtn {
      margin-right: 0.5rem;
    }
  }

  button, button:not(.dropdown) {
    &.sapMBtn {
      outline: 0;
      &:hover > .sapMBtnHoverable {
        .btnRedHover;

        &.sapMBtnTransparent {
          .btnRedHover;
        }

        &.sapMToggleBtnPressed:not(.sapMBtnActive) {
          .btnRedHover;
        }
        .sapMBtnHoverable.sapMToggleBtnPressed:hover {
          .btnRedHover;
        }
      }
    }

    .sapMBtnInner {
      font-size: 1.125rem;
      .btnRed
    }
  }

  .blockForm {
    overflow: visible;
    padding-bottom: 1rem;
    border-bottom: 2px solid @c_5;

    .sapUiBlockLayoutCell {
      margin: 0 0.5rem;
    }

    .sapUiBlockCellContent {
      margin-top: 16px;
    }

    .wHeadline {
      .sapUiBlockLayoutCell {
        margin: 0;
      }
    }

    .sapUiBlockCellTitle {
      margin-bottom: 0;
    }

    .sapMSlt {
      margin-top: 1px;
    }

    .formText {
      font-size: 1rem !important;
      line-height: 33px;
      height: 40px;
      vertical-align: bottom;
    }

    .sapMRbB {
      .sapMRbBInn {
        margin-top: 1px;
      }
    }

    .sapMRb {
      clear: none;
      margin: 0.5rem 0.75rem 0.5rem 0;

      &:focus:before {
        width: 100% !important;
        left: 0 !important;
        top: 0.125rem !important;
      }
      .sapMRbB {
        top: 0;
        height: 2rem;
        width: 2rem;

        .sapMRbBOut {
          margin: 0;
          padding-top: 0;
        }
      }
      .sapMRbBLabel {
        max-width: ~"calc(100% - 2rem + 1px)";
      }

      .sapMRbBLabel {
        margin-left: 0;
        margin-right: .8125rem;
        top: 13px!important;
        left: 3px !important;
        position: relative !important;
      }
    }



    .sapMRbG {
      .sapMRb {
        &:focus:before {
          width: 100% !important;
          left: 0 !important;
          top: 0.125rem !important;
        }
        .sapMRbB {
          height: 2rem;
          width: 2rem;

          .sapMRbBOut {
            margin: 0 0.5rem 0 0;
            padding-top: 0 !important;
          }
        }
        .sapMRbBLabel {
          padding-left: 2rem;
          max-width: ~"calc(100% + 1px)";
          height: 2rem;
          line-height: 2rem;
        }
      }
    }
    .sapMCb {
      &.firstChild {
        padding-left: 35px;
        .sapMCbMark {
          left: 0;
        }
      }

      @media (max-width: 560px) {
        padding-left: 35px;
        .sapMCbMark {
          left: 0;
        }
      }

      .sapMLabel {
        cursor: pointer;
      }
    }

    .sapMDP {
      width: 9rem !important;
    }

    .sapMLabel {
      text-overflow: clip;
      overflow: visible;
      white-space: normal;
    }

    .sapMCbLabel {
      line-height: normal;
    }

    .sapMLabel:not(.sapMRbBLabel):not(.sapMCbLabel) {
      padding: 0 0 3px 0;
      cursor: pointer;
    }

    .sapUiHLayout {
      position: relative;
      .sapMLabel:not(.sapMRbBLabel):not(.sapMCbLabel) {

        //float: left;
      }
      .oevbsIco {
        position: absolute;
        top: 0;
        margin: 0 0 0 8px;
      }
    }

    .oevbsIco {
      margin: -2px 0 0 8px;

      &.fleft {
        margin-top: 12px;
        margin-left: -8px;
        margin-right: 1rem;

      }

      &.afterLbl {
        //position: absolute;
        //margin-top: 16px;
      }
    }
  }

  .sapMSF {
    display: inline-block;
  }

  .nextBG {

    background: @c_5;
    padding: 1rem 0.5rem;
    .h3 !important;

    h3 {
      .h3 !important;
    }

    .sapMFT {
      display: inline-block;
      font-size: inherit !important;
      font-weight: inherit !important;
    }

    .sapMBtn {
      margin-top: 0.5rem;
      margin-right: 0 !important;
    }

    .sapUiBlockLayoutCell,
    .sapUiBlockCellContent {
      margin: 0 !important;
    }
  }

  .sapMCb {
    margin: 0.5rem 0;
    height: inherit !important;
    line-height: normal !important;
    > label {
      text-overflow: clip !important;
      overflow: visible !important;
      white-space: normal !important;
      line-height: normal;
    }
    .sapMCbBg {
      top: 0 !important;
    }
  }

  .sapMRbBLabel {
    height: inherit !important;
    line-height: normal !important;
    &:before {
      display: none;
    }
  }

  .noOverflow {
    overflow: visible !important;
  }

  .noWhiteSpace {
    white-space: normal !important;
  }

  .mw80 {
    min-width: 80px;
  }
}
.sapMInputBaseIcon {
  background: #0d39ab;
}