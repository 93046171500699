@swipe_bg: #e3ecf9;
@swipe_border: #e3ecf9;
@defaultSize: 60px;


@-webkit-keyframes maxi {
  to {width: ~"calc( 100% - @{defaultSize})";}
  from {width: @defaultSize;}
}

@keyframes maxi {
  to {width: ~"calc( 100% - @{defaultSize})";}
  from {width: @defaultSize;}
}

@-webkit-keyframes mini {
  from {width: ~"calc( 100% - @{defaultSize})";}
  to { width: @defaultSize; }
}

@keyframes mini {
  from {width: ~"calc( 100% - @{defaultSize})";}
  to { width: @defaultSize; }
}

@-webkit-keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}



.productsFrgmt {
  width:100%;

  &:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
  }

  .prodWrapper {
    margin: 0 auto;
    width:100%;
    max-width: 840px;
    min-width: 320px;
    display:block;

    .swipeWrapper {
      background: @swipe_bg;
      width: 100%;
      position: relative;

      .checkfield {
        background-color: @oevbs_blue;
        background-image: url('./../img/decline3.png');
        background-size:  50px 50px;
        background-repeat: no-repeat;
        background-position: center center;
        height: @defaultSize;
        width:  @defaultSize;
        display:inline-block;
        float: left;
        cursor: pointer !important;
      }

      .prodSwipe {
        width: ~"calc(100% - @{defaultSize})";
        height: @defaultSize;
        display:inline-block;
        float: left;

        .triangle {
          width: 0;
          height: 0;
          position: absolute;
          z-index: 1;
          //border-left: 20px solid @oevbs_blue;
          border-left: 20px solid @blue;
          border-top: 30px solid transparent;
          border-bottom: 30px solid transparent;
          left: 120px;
          top: 0px;
        }
        .sapUiIcon {
          font-weight: bold;
          &:focus {
            outline: none !important
          }
          &.moreInfoLink {
            font-size: 26px !important;
            color:  @oevbs_blue;
            padding: 5px;
            border-radius: 15px;
            cursor: help !important;
            margin-left: 20px;
            position: absolute;
            right: 5px;
            top: 10px;
          }

        }

        .swiper {
          //background-color: @oevbs_blue;
          background: @bg_header_gradient_oevbs;
          overflow: hidden;
          height: @defaultSize;
          width: @defaultSize;
          min-width: @defaultSize;
          display: inline-block;
          position: absolute;
          z-index: 2;


          -webkit-animation: mini 0.5s ease-in forwards;
          animation: mini 0.5s ease-in forwards;

          .title {
            color: #fff !important;
          }
          .subtitle {
            color: #fff !important;
          }
          .whiteIcon {
            color: #fff !important;
          }
          .sapUiIcon {

            &.close {
              cursor: pointer;
              color: #fff !important;
              font-size: 2rem;
              z-index: 2;
              position: absolute;
              right: 25px;
              top:20px;
              display: none;
            }

            &.moreInfoLink {
              //font-size: 12px;
              font-size: 26px !important;
              color: #fff !important;
              position: absolute;
              right: 5px;
              top: 10px;
              display: none;
            }
          }
        }

        .img {
          background-size: contain;
          background-color: transparent;
          background-position: center center;
          height: @defaultSize;
          width: @defaultSize;
          position: absolute;
          z-index: 3;
          cursor: pointer !important;
        }

        //.noHandcursor {
        //  cursor: auto !important;
        //}

        .title {
          margin-left: 85px;
          margin-right: 2px;
          display: inline-block;
          line-height: @defaultSize;
          vertical-align: middle;
          height: @defaultSize;
          font-size: 1.3rem !important;
          cursor: pointer !important;
          &.wSubtitle {
            padding-top:20px;
            line-height: unset;
            vertical-align: unset;
            height: unset;
          }
        }
        .subtitle {
          display: block;
          font-size: 0.7rem !important;
        }
      }
    }

    .disabledTxt {
      position: absolute;
      left: 125px;
      top: 40px;
      right: 0;
      font-size: 0.8rem !important;
    }

    &[data-type="phv"] {
      .img {
        background-image: url('./../img/products/white/ph.png');
      }
    }
    &[data-type="ds"] {
      .img {
        background-image: url('./../img/products/white/ds.png');
      }
    }
    &[data-type="unf"] {
      .img {
        background-image: url('./../img/products/white/un.png');
      }
    }
    &[data-type="hr"] {
      .img {
        background-image: url('./../img/products/white/hr.png');
      }
    }
    &[data-type="gl"] {
      .img {
        background-image: url('./../img/products/white/gl.png');
      }
    }

    &[data-checked="X"] {
      .swipeWrapper {
          .checkfield {
            background-image: url('./../img/check.png') !important;
          }

          .swiper {
            width: ~"calc( 100% - @{defaultSize})";
            -webkit-animation: maxi 0.5s ease-in forwards;
            animation: maxi 0.5s ease-in  forwards;

            .sapUiIcon{//Slider-Icon nicht mehr sichtbar
              &.close {
                //display: inline-block;
                opacity: 0;
                -webkit-animation: fade 0.4s ease-in 0.4s forwards;
                animation: fade 0.4s ease-in 0.4s forwards;
              }
              &.moreInfoLink {
                display: inline-block;
                opacity: 0;
                -webkit-animation: fade 0.4s ease-in 0.4s forwards;
                animation: fade 0.4s ease-in 0.4s forwards;
              }
            }

          }
        }
    } //checkClosed
    &[data-disabled="X"] {
      .checkfield {
        opacity: 0.8;
        background-image: url('./../img/decline3.png');
      }
      .moreInfoLink {
        //right: 320px !important;
      }
      .img {
        opacity: 0.8;
      }
      .swiper {

        opacity: 0.8;
        animation: none !important;
      }
      .triangle {
        display: none !important
      }
    }
    .detailInformation {
      background-color: @c_5;
      width: ~"calc(100% - 20px)";
      padding: 10px 10px 0 10px;
      margin-top: -7px;
      display:none;

      .polprHighlights {
        .sapMFT {
          font-size: 1.15rem !important;
          vertical-align: middle;

          .fo-like {
            font-size: 0.85rem;
            color: @oevbs_blue !important;
            margin-right: 5px;
          }
        }
        .sapUiVltCell {
          margin-bottom: 5px;
        }
      }

    .polprHighlightsTitle {
      width: 100%;
      .sapMFT {
        font-size: 1.15rem !important;
        vertical-align: middle;
        overflow: hidden;
      }
      .sapUiVltCell {
        margin-bottom: 10px;
      }
    }

      .polprDetails {
        margin: 5px 0 10px 0;
      }
    }
  }
}

.oevbsIco[data-atbottom="X"] {
    bottom: -2px;
    position: absolute;
  }

