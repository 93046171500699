.sap-desktop {
  .sapMPopover {
    z-index: 102 !important;
    &.sapMSltPicker {
      max-height: 450px !important;
      .sapMPopoverCont {
        height: 290px !important;
        padding-bottom: 20px !important;
        .sapMPopoverScroll {
          // max-height: 400px !important; //statt 2rem wg. abgeschnittener Bestnr-Dropdown
        }
      }
    }
  }
}

.sapMPopoverCont {
  padding: 0.5rem;
}

.sapUiSizeCompact .sapMSelectListItemBase {
  height: 1.5rem !important; //statt 2rem wg. abgeschnittener Bestnr-Dropdown
  font-family: Arial,Helvetica,sans-serif;
}