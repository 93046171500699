.propertiesBitAutoComplete {
  background: transparent;
  position: relative;
  display: inline-block;
  vertical-align: top;
  outline: none;
  padding: 0.25rem 0;
  border-radius: 0;
  height: 2.5rem;
  width: 100%;
  margin-top: 0.25rem;
}

.propertiesBitAutoCompleteInner {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  font-size: 0.875rem;
  line-height: normal;
  padding: 0 0.75rem;
  height: 100%;
  box-sizing: border-box;
}

.bitAutoComplete {
  .propertiesBitAutoComplete !important;

  .sapUiTfCombo {
    font-family: @fontFamily !important;
  }

  .sapUiTfInner {

    .propertiesBitAutoCompleteInner !important;
  }

  &.error, &.errorCtrl {
    border-color: @oevbs_red;
  }
}
.sapUiLbx  {

  .sapUiLbxI {
    height: 3rem;
    .sapUiLbxITxt {
      font-size: 0.875rem;
    }
  }
}