.sap-phone .agencyFrgmt .widthFullForMoble,
.sap-tablet .agencyFrgmt .widthFullForMoble {
  width: 100% !important;
}
.agencyFrgmt .agencyRow .sapUiBlockLayoutCell {
  position: relative;
  background-color: #fff;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin: 0 0 0;
  text-align: start;
}
.agencyFrgmt .agencyRow .sapUiBlockLayoutCell.title h2 {
  text-align: start;
  font-size: 1.5rem !important;
}
.agencyFrgmt .agencyHeadline {
  margin-left: -0.1rem !important;
}
.agencyFrgmt .agencyHBox {
  width: 80%;
  height: auto;
  margin: 0 auto;
  justify-content: center;
}
.agencyFrgmt .agencyVBox {
  margin-top: 15px;
  margin-bottom: 15px;
  z-index: 0;
  position: relative;
  background: #FFF;
  padding: 10px;
  box-sizing: border-box;
  transition: box-shadow 0.2s ease, transform 0.2s ease, z-index 0s 0.2s ease;
  box-shadow: 0 0 0 1px #E2E9ED;
  max-width: 300px;
}
.agencyFrgmt .agencyVBox:before {
  content: "";
  display: block;
}
.agencyFrgmt .agencyVBox:hover {
  z-index: 1;
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  transition: box-shadow 0.2s ease, transform 0.2s ease, z-index 0s 0s ease;
  cursor: pointer;
}
.agencyFrgmt .agencyImage {
  padding-top: 20px;
  max-width: 65%;
  height: auto;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.agencyFrgmt .agency_name {
  color: #313435;
  font-family: 'Roboto', sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.625rem;
  padding-top: 10px;
}
.agencyFrgmt .agencyText {
  text-align: center;
}
.agencyFrgmt .agencyInfo {
  text-align: center;
  color: #6F808A;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem !important;
}
.agencyFrgmt .arrowRight {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.agencyFrgmt .agencyNextButton {
  margin-top: -10px;
}
.agencyFrgmt .agencyInputSearch {
  min-width: 30em;
}
.agencyFrgmt .agencyResultImg {
  max-height: 250px;
}
.visColumn {
  padding: 0 !important;
}
.sapUiCalHead > button,
.sapUiCalCancel {
  background-color: lightgrey !important;
}
#visBody button {
  background-color: transparent;
  color: inherit;
  margin: 0;
  font-size: 1.125rem;
  padding: 0;
  border-radius: 0;
}
#visBody button:hover,
#visBody button:active,
#visBody button:focus {
  box-shadow: none;
}
#visBody p:before,
#visBody p:after,
#visBody span:not([class]):before,
#visBody span:not([class]):after {
  content: initial;
}
#visBody:not(.noEmbeded) section:not(.sapMPageEnableScrolling) {
  overflow: visible !important;
}
#visBody .sapUiIcon:before {
  display: initial;
}
#visBody input[type="search"] {
  border: none;
  box-shadow: none;
  font-size: 0.875rem;
  background: transparent;
  width: 100%;
  height: 2.375rem;
  outline: none;
  padding: 0;
  margin: 0;
  text-indent: 0.125rem;
  vertical-align: top;
  text-overflow: ellipsis;
}
#visBody input[type="search"]:hover,
#visBody input[type="search"]:active,
#visBody input[type="search"]:focus {
  border: none !important;
  text-shadow: none !important;
}
#visBody .oevbsIco:before,
#visBody .sapMSltArrow:before,
#visBody .fo-like:before {
  display: inline-block;
}
#visBody .oevbsIco:after,
#visBody .sapMSltArrow:after,
#visBody .fo-like:after {
  display: none;
  content: "";
}
@media (min-width: 880px) {
  #visBody .dataFrgmt {
    position: absolute;
    top: 2px;
    left: 0;
  }
}
@-webkit-keyframes shake {
  0% {
    transform: translate(1px, 1px);
  }
  10% {
    transform: translate(-1px, -2px);
  }
  20% {
    transform: translate(-3px, 0px);
  }
  30% {
    transform: translate(3px, 2px);
  }
  40% {
    transform: translate(1px, -1px);
  }
  50% {
    transform: translate(-1px, 2px);
  }
  60% {
    transform: translate(-3px, 1px);
  }
  70% {
    transform: translate(3px, 1px);
  }
  80% {
    transform: translate(-1px, -1px);
  }
  90% {
    transform: translate(1px, 2px);
  }
  100% {
    transform: translate(1px, -2px);
  }
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px);
  }
  10% {
    transform: translate(-1px, -2px);
  }
  20% {
    transform: translate(-3px, 0px);
  }
  30% {
    transform: translate(3px, 2px);
  }
  40% {
    transform: translate(1px, -1px);
  }
  50% {
    transform: translate(-1px, 2px);
  }
  60% {
    transform: translate(-3px, 1px);
  }
  70% {
    transform: translate(3px, 1px);
  }
  80% {
    transform: translate(-1px, -1px);
  }
  90% {
    transform: translate(1px, 2px);
  }
  100% {
    transform: translate(1px, -2px);
  }
}
.shake {
  animation: shake 0.5s ease-in forwards;
}
.btnBlue {
  background: #0d39ab;
  border-color: #0d39ab;
  color: #fff;
  text-shadow: none;
}
.btnBlue span {
  color: inherit;
}
.btnBlue .sapUiIcon {
  color: #fff;
}
.btnRed {
  background: #da291c;
  border-color: #da291c;
  color: #fff !important;
  text-shadow: none;
  margin: 5px 2px 5px 0;
}
.btnRed span {
  color: inherit;
}
.btnRed .sapUiIcon {
  color: #fff;
  background: transparent !important;
}
.btnRedHover {
  background: #da291c;
  color: #fff !important;
  text-shadow: none;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
.btnRedHover span {
  color: inherit;
}
.btnGrey {
  background: #d9e0f2;
  border-color: #bfcbe9;
  color: #4d4d4c !important;
  text-shadow: none;
}
.btnGrey span {
  color: inherit;
}
.btnGreyHover {
  background: #d9e0f2;
  text-shadow: none;
  color: #4d4d4c !important;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
.btnGreyHover span {
  color: inherit;
}
.toggleBtnPressed {
  background: #da291c;
  border-color: #da291c;
  color: #fff;
  text-shadow: none;
}
.toggleBtnPressedBlue {
  background: #1b3d93;
  border-color: #0d39ab;
  color: #fff;
  text-shadow: none;
}
.body {
  background: #fff;
  color: #3c3c3b;
  padding: 0;
  margin: 0;
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-weight: 200;
  font-style: normal;
  line-height: 1.25rem;
  position: relative;
  cursor: default;
  width: 100%;
  /* overflow-y: overlay; 2.Balken halb verstecken */
}
.fontProperties {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-size: 1rem;
  color: #3c3c3b;
}
.blockHeaderBg {
  background: linear-gradient(to right, #0d39ab, #1b3d93);
  background-size: 100% 55px;
  background-repeat: no-repeat;
}
.blockHeaderBg .sapUiBlockCellTitle.sapUiBlockCellBegin {
  color: #fff;
  margin-bottom: 1.5rem;
}
.red {
  color: #da291c !important;
}
.green {
  color: #97d700 !important;
}
.headline {
  font-size: 2.25rem;
  line-height: 2.625rem;
  color: #0d39ab;
  margin-bottom: 0.938rem;
}
@media (min-width: 320px) and (max-width: 480px) {
  .headline {
    font-size: 1.75rem !important;
    line-height: 1.75rem !important;
  }
}
.h3 {
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 1.625rem;
  color: #3c3c3b;
}
@media (min-width: 320px) and (max-width: 480px) {
  .h3 {
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
  }
}
* {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif;
}
.sapMLabel {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-size: 1rem !important;
  color: #3c3c3b !important;
  cursor: pointer;
}
.sapUiGlobalBackgroundColor,
.sapMGlobalBackgroundColor {
  background-color: #fff !important;
  background-image: none !important;
}
.sapMInputBaseContentWrapper {
  border: 0.4px solid #bfbfbf !important;
  background: #ffffff;
}
.sapMCrslBulleted > span:before {
  content: none!important;
}
.sapMCrslBulleted > span:after {
  content: none!important;
}
.sapMInputHighlight:after {
  content: none!important;
}
.sapMInputHighlight:before {
  content: none!important;
}
.fb {
  font-weight: bold;
}
.sapMRbSel .sapMRbBInn {
  background-color: #0d39ab !important;
}
.sapMRbHoverable:hover {
  border-color: #0d39ab !important;
}
.sapMCbHoverable:hover {
  border-color: #0d39ab !important;
}
.sapMRbB .sapMRbBOut {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-size: 1rem;
  color: #3c3c3b;
}
.sapMInputBaseInner:not(.sapMInputBaseReadonlyInner):not(.sapMInputBaseStateInner):not(.sapMInputBaseDisabledInner):hover {
  border-color: #0d39ab;
}
.sapMCbBg.sapMCbMarkChecked:before {
  color: #0d39ab !important;
}
.sapMSltHoverable:hover {
  border-color: #0d39ab !important;
}
.sapMMsgStrip {
  width: calc(100%);
  margin: 0 1rem 1rem 1rem;
}
.sapMMsgStripIcon > .sapUiIcon {
  font-size: 1rem;
}
.sapUiResponsiveMargin {
  margin: 0 0 1rem 0 !important;
}
.sapMInputBaseInner {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-size: 1rem;
  color: #3c3c3b;
}
.valueStateError {
  border: 2px solid #d62018;
}
.cbValueStateError .sapMCbBg {
  border: 2px solid #d62018;
}
.sapMTitle {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-size: 1rem;
  color: #3c3c3b;
}
.sapMText,
.sapMFT,
.sapMText > div,
.sapMText > span {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-size: 1rem;
  color: #3c3c3b;
  font-size: 1rem !important;
  line-height: 1.375;
  font-weight: normal;
}
.bitMTextRB {
  position: relative;
  top: 10px;
}
.bitUiSmallMarginTop {
  margin-top: 0.8rem !important;
}
.sapUiSizeCompact .sapUiSmallMarginTop {
  margin-top: 0.5rem !important;
}
.bitHBoxH {
  height: 50px;
}
.msgContainer {
  width: 100%;
  /*margin-bottom: 1rem;*/
}
.msgContainer .sapMMsgStrip {
  margin: 0 0 0.3rem 0;
  min-height: 2rem;
}
.sapUiComponentContainer,
.sapUiComponentContainer > div {
  width: 100%;
}
.sapUiComponentContainer {
  overflow: hidden;
}
.mb1rem {
  margin-bottom: 1rem;
}
.mt1rem {
  margin-top: 1rem;
}
.mbt1rem {
  margin: 1rem 0;
}
.sapMLabelMaxWidth.lblMaxWidth100,
.lblMaxWidth100 {
  max-width: 100%;
}
.sapMInputBaseWarningInner {
  border-color: #bfbfbf;
  border-width: 1px;
}
.sapMInputBaseInner:not(.sapMInputBaseReadonlyInner):not(.sapMInputBaseStateErrorInner):not(.sapMInputBaseDisabledInner):hover {
  border-color: #0d39ab;
}
.sapMInputBaseInner:not(.sapMInputBaseReadonlyInner):not(.sapMInputBaseStateInner):focus {
  border-color: #0d39ab;
}
.sapUiLnk {
  font-size: 13px!important;
}
.confirmationLbl {
  display: inline-block;
  width: 150px;
  font-weight: bold;
  padding-right: 5px;
}
.confirmationLbl.longTxt {
  width: 260px;
}
.confirmationVal {
  display: inline-block;
}
.sapMTile.sapMTileActive {
  background: #0d39ab;
}
.sapMStdTileIconDiv {
  color: #0d39ab;
}
.sapMIBar-CTX .sapMTitle {
  color: white;
}
.sapMBar .sapMBarContainer .sapMInputSuggInDialog .sapMInputBaseInner {
  color: inherit;
}
.sapMBar .sapMBarContainer .sapMInputBaseInner {
  color: white;
}
.sapMMessagePage > .sapMPage section {
  max-width: 80%;
}
.contractList .sapMLIB {
  border-bottom: none;
}
.sapUiFormEdit .contractList .sapMText {
  min-height: 1rem;
  padding-top: 0.3125rem;
  padding-bottom: 0;
  line-height: 1.000;
}
.sapUiForm .sapUiFormTitle {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
}
.sapMList .sapMIBar .sapMBarChild .sapMBtnIcon {
  color: black !important;
}
.sapMList .sapMIBar .sapMBarChild .sapMBtnHoverable .sapMBtnIcon {
  color: white;
}
.sapMList .sapMLIB.sapMGHLI {
  color: #0d39ab;
  background: #f7f7f7;
  border-bottom: 1px solid #0d39ab;
}
.sapMLIB.sapMLIBActive {
  background: #0d39ab;
  color: white;
}
.sapMLIB.sapMLIBActive .sapMSLIImgIcon {
  color: #fff;
}
.sapMSLIImgIcon {
  margin-left: 0;
}
.sapMSegB .sapMSegBBtn {
  color: #0d39ab;
}
.sapMSegBBtn.sapMSegBBtnSel {
  background: #d62018;
  color: #fff;
  border-color: #d62018;
}
.sapMSegBBtn.sapMSegBBtnFocusable.sapMSegBBtnSel:hover,
.sapMSegBBtnFocusable.sapMBtnActive:hover {
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
.sapMSegBBtnFocusable:hover {
  background: #0d39ab !important;
  color: white;
  border-color: #0d39ab;
}
.sapMSegBBtn.sapMSegBBtnFocusable.sapMSegBBtnSel:hover,
.sapMSegBBtnFocusable.sapMBtnActive:hover {
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
.sapMSegBBtn.sapMSegBBtnFocusable.sapMSegBBtnSel {
  border-right: 0 solid #d62018;
}
.bitF14 {
  font-size: 1rem;
}
.bitF16 {
  font-size: 1.1rem;
}
.sapUiDlg div {
  float: none;
}
/*CustomHeader Panel*/
.sapMPanelHdr .sapMBtnIcon {
  color: white;
}
.sapMPanelHdr .sapMTitle {
  color: #0d39ab !important;
  text-shadow: 0 0.0625rem 0 #000000 !important;
  /*font-size: 13px !important;*/
}
.sapMPopover.helpPopover .sapMPanelHdr .sapMTitle > span {
  color: #000000 !important;
  text-shadow: 0 0.0625rem 0 #ffffff !important;
}
/*CustomHeader Panel*/
/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^ SAP BUTTON Override^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
html.sap-desktop .sapMToggleBtnPressed,
html.sap-desktop .sapMIBar-CTX .sapMToggleBtnPressed,
html.sap-desktop .sapMTB-Transparent-CTX .sapMBtnInner.sapMToggleBtnPressed,
html.sap-desktop .sapMBtn[data-btnPressed] .sapMBtnInner {
  background: #1b3d93 !important;
  border-color: #0d39ab !important;
  color: #fff !important;
  text-shadow: none !important;
}
html.sap-desktop .sapMBtn:hover > .sapMBtnHoverable .sapMBtnIcon {
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
html.sap-desktop .sapMBtn:hover > .sapMBtnTransparent.sapMBtnHoverable {
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
html.sap-desktop .sapMBtn:hover .sapMBtnHoverable.sapMToggleBtnPressed,
html.sap-desktop .sapMBtn .sapMBtnHoverable.sapMToggleBtnPressed:hover,
html.sap-desktop .sapMTB-Transparent-CTX .sapMBtnHoverable.sapMToggleBtnPressed:hover {
  background-image: none;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
html.sap-desktop .sapMBtnTransparent > .sapMBtnIcon {
  color: inherit !important;
}
html.sap-desktop .sapMBtn:focus > .sapMFocusable {
  outline: none !important;
}
html.sap-phone .sapMToggleBtnPressed,
html.sap-tablet .sapMToggleBtnPressed,
html.sap-phone .sapMIBar-CTX .sapMToggleBtnPressed,
html.sap-tablet .sapMIBar-CTX .sapMToggleBtnPressed,
html.sap-phone .sapMTB-Transparent-CTX .sapMBtnInner.sapMToggleBtnPressed,
html.sap-tablet .sapMTB-Transparent-CTX .sapMBtnInner.sapMToggleBtnPressed,
html.sap-phone .sapMBtn[data-btnPressed] .sapMBtnInner,
html.sap-tablet .sapMBtn[data-btnPressed] .sapMBtnInner {
  background: #1b3d93 !important;
  border-color: #0d39ab !important;
  color: #fff !important;
  text-shadow: none !important;
}
html.sap-phone .sapMBtnTransparent > .sapMBtnIcon,
html.sap-tablet .sapMBtnTransparent > .sapMBtnIcon {
  color: inherit !important;
}
button,
button:not(.dropdown) {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  background: transparent;
}
button[aria-pressed='false'].sapMBtn:hover > .sapMBtnHoverable,
button:not(.dropdown)[aria-pressed='false'].sapMBtn:hover > .sapMBtnHoverable {
  background: #d9e0f2;
  text-shadow: none;
  color: #4d4d4c !important;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
button[aria-pressed='false'].sapMBtn:hover > .sapMBtnHoverable span,
button:not(.dropdown)[aria-pressed='false'].sapMBtn:hover > .sapMBtnHoverable span {
  color: inherit;
}
button[aria-pressed='false'] .sapMBtnInner,
button:not(.dropdown)[aria-pressed='false'] .sapMBtnInner {
  background: #d9e0f2;
  border-color: #bfcbe9;
  color: #4d4d4c !important;
  text-shadow: none;
}
button[aria-pressed='false'] .sapMBtnInner span,
button:not(.dropdown)[aria-pressed='false'] .sapMBtnInner span {
  color: inherit;
}
button[aria-pressed='false'] .sapMBtnInner.sapMBtnActive,
button:not(.dropdown)[aria-pressed='false'] .sapMBtnInner.sapMBtnActive {
  border-color: #4d4d4c;
}
.sapUiSizeCompact .sapMBtn {
  margin: 0 0.2rem;
}
.sapMBtnIcon {
  margin: 0 !important;
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
  background-color: inherit !important;
  color: white;
}
/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^ SAP BUTTON Override ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^ ToolbarButton ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
.tbInfoBtn {
  border: 1px solid whitesmoke;
}
.tbInfoBtn.sapMBtn {
  height: 1.5rem;
}
.tbInfoBtn .sapMBtnInner {
  height: 1.125rem !important;
  min-width: 1.5rem;
}
.tbInfoBtn .sapMBtnIcon {
  line-height: 1.00;
  width: 0.5rem;
  font-size: 0.75rem;
  margin-left: 0.4375rem;
  margin-right: 0.4375rem;
}
.tbInfoBtn.sapMBtn:hover > .sapMBtnInner {
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^ ToolbarButton ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
.sapMIBar.sapMFooter,
.sapMIBar.sapMFooter-CTX {
  background-color: #DFDFDF !important;
}
.sapMSwt,
.sapMSwt:hover {
  background: #fff;
  color: #0d39ab !important;
  font-size: 1rem;
}
.sapMSwt.sapMSwtAcceptReject.sapMSwtOff,
.sapMSwt:hover.sapMSwtAcceptReject.sapMSwtOff,
.sapMSwt.sapMSwtAcceptReject.sapMSwtOn,
.sapMSwt:hover.sapMSwtAcceptReject.sapMSwtOn {
  border-color: #0d39ab;
}
.sapMSwt.sapMSwtAcceptReject.sapMSwtOff .sapMSwtHandle,
.sapMSwt:hover.sapMSwtAcceptReject.sapMSwtOff .sapMSwtHandle,
.sapMSwt.sapMSwtAcceptReject.sapMSwtOn .sapMSwtHandle,
.sapMSwt:hover.sapMSwtAcceptReject.sapMSwtOn .sapMSwtHandle {
  border-color: #0d39ab;
  background: #0d39ab;
}
.sapMSwt.sapMSwtAcceptReject .sapMSwtLabel::after,
.sapMSwt:hover.sapMSwtAcceptReject .sapMSwtLabel::after {
  display: none;
  content: '';
}
.sapMSwt.sapMSwtAcceptReject .sapMSwtLabelOn::before,
.sapMSwt:hover.sapMSwtAcceptReject .sapMSwtLabelOn::before {
  color: #0d39ab;
  display: inline-block;
}
.sapMSwt.sapMSwtAcceptReject .sapMSwtLabelOff,
.sapMSwt:hover.sapMSwtAcceptReject .sapMSwtLabelOff {
  padding: 0 0.5rem;
}
.sapMSwt.sapMSwtAcceptReject .sapMSwtLabelOff::before,
.sapMSwt:hover.sapMSwtAcceptReject .sapMSwtLabelOff::before {
  color: #0d39ab;
  padding-right: 0.5rem;
  display: inline-block;
}
.sapMLnk {
  color: #d62018 !important;
  padding-top: 10px;
  white-space: normal!important;
}
.sapMLnk .icoL {
  margin-right: 5px;
}
.sapUiBlockCellTitle {
  box-sizing: unset !important;
}
.sapUiBlockCellTitle:after,
.sapUiBlockCellTitle:before {
  box-sizing: unset !important;
}
.sapMCb:not(.sapMCbBgDis).sapMCbHasLabel:focus:before {
  height: 100%;
  top: -0.185rem !important;
}
:not(.sapMSFDisabled) > .sapMSFF:hover {
  border-color: #0d39ab;
}
#content {
  padding: 0px 0px 0px 0px !important;
  background: white !important;
}
.sapUiBlockLayoutSizeXL .sapUiBlockCellContent {
  padding: 0 !important;
}
.sapUiBlockLayoutSizeL .sapUiBlockCellContent {
  padding: 0 !important;
}
.sapUiBlockLayoutSizeM .sapUiBlockCellContent {
  padding: 0 !important;
}
.sapUiBlockLayoutSizeS .sapUiBlockCellContent {
  padding: 0 !important;
}
.sapUiBlockLayoutSizeXS .sapUiBlockCellContent {
  padding: 0 !important;
}
.sapUiBlockCellTitle {
  font-weight: normal !important;
  font-size: 1.375rem !important;
  line-height: 1.625rem !important;
  color: #3c3c3b !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .sapUiBlockCellTitle {
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
  }
}
.sapUiBlockLayout {
  margin-bottom: 1rem;
}
.sap-desktop .sapMPopover {
  z-index: 102 !important;
}
.sap-desktop .sapMPopover.sapMSltPicker {
  max-height: 450px !important;
}
.sap-desktop .sapMPopover.sapMSltPicker .sapMPopoverCont {
  height: 290px !important;
  padding-bottom: 20px !important;
}
.sapMPopoverCont {
  padding: 0.5rem;
}
.sapUiSizeCompact .sapMSelectListItemBase {
  height: 1.5rem !important;
  font-family: Arial, Helvetica, sans-serif;
}
.sapMDialogFooter {
  padding: 0 !important;
}
.sapMDialogFooter .sapMBtn {
  padding: 0;
}
.sapMDialogFooter .sapMBtn.sapMBarChild {
  background-color: #d62018;
}
.sapMDialogFooter .sapMBtn.sapMBarChild bdi {
  color: #fff !important;
}
/**
 * Mobile und Tablet
 */
.sap-phone #High5App,
.sap-tablet #High5App {
  margin: 0 0.25rem;
  width: calc(100% - 0.5rem) !important;
}
.sap-phone .sapUiRtt,
.sap-tablet .sapUiRtt {
  left: 0 !important;
}
/**
  * Mobile
 */
.sap-phone .sapMRb {
  width: 100%;
}
.sap-phone .sapMRb.wInfo {
  width: calc(100% - 40px);
}
.sap-phone .sapMBtnIcon {
  line-height: 2.25rem;
}
.sapMCrslBulleted > span:before {
  content: none!important;
}
.sapMCrslBulleted > span:after {
  content: none!important;
}
.sapMInputHighlight:after {
  content: none!important;
}
.sapMInputHighlight:before {
  content: none!important;
}
.sapMDialog {
  z-index: 1112 !important;
}
.sapMDialog .sapMSFI[type="search"] {
  border: none !important;
  box-shadow: none;
  font-size: 0.875rem;
  background: transparent;
  width: 100%;
  height: 2.375rem;
  outline: none;
  padding: 0;
  margin: 0;
  text-indent: 0.125rem;
  vertical-align: top;
  text-overflow: ellipsis;
}
.sapMDialog .sapMSFI[type="search"]:hover,
.sapMDialog .sapMSFI[type="search"]:active,
.sapMDialog .sapMSFI[type="search"]:focus {
  border: none !important;
  text-shadow: none !important;
  background: transparent;
}
.sapMDialog .sapMSFF {
  border: none;
}
.sapMDialog .sapMFT {
  padding: 0.5rem;
}
.helpdeskWrapper {
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: 0.75rem;
}
.helpdeskWrapper .sapUiTv {
  color: #0d39ab;
}
.helpdeskWrapper .sapUiIcon {
  color: #0d39ab;
  margin: 0 0 0 5px;
  cursor: context-menu;
}
.hdDialog {
  width: 100%;
  height: 100%;
  z-index: 999 !important;
}
.hdDialog .sapMDialogScroll {
  height: 100%;
}
.hdDialog .sapMDialogScroll .sapMDialogScrollCont {
  height: calc( 100% - 30px);
}
.hdDialog .sapMDialogScroll .tabfilter {
  height: calc( 100% - 30px);
}
.hdDialog .sapMDialogScroll .tabfilter .sapMITBContainerContent {
  height: calc( 100% - 30px);
}
.hdDialog .sapMDialogScroll .tabfilter .sapMITBContainerContent .sapMITBContent {
  height: 100%;
}
.hdDialog .sapMDialogScroll .tabfilter .sapMITBContainerContent .sapMITBContent > .sapUiVlt.sapuiVlt {
  height: 100%;
}
.hdDialog .sapMDialogScroll .tabfilter .sapMITBContainerContent .sapMITBContent > .sapUiVlt.sapuiVlt .sapUiVltCell.sapuiVltCell:last-child {
  height: calc( 100% - 30px);
  display: flex;
  flex-direction: column;
}
.hdDialog .sapMDialogScroll .tabfilter .sapMITBContainerContent .sapMITBContent > .sapUiVlt.sapuiVlt .sapUiVltCell.sapuiVltCell:last-child .sapMTextArea {
  height: 100%;
}
.hdDialog .sapMDialogScroll .tabfilter .sapMITBContainerContent .sapMITBContent > .sapUiVlt.sapuiVlt .sapUiVltCell.sapuiVltCell:last-child .sapMTextArea textarea {
  flex-grow: 1;
}
.hdDialog .sapMDialogScroll .hdMenuBtns {
  margin-right: 5px;
}
.hdDialog .sapMDialogScroll .hdMenuBtns.active {
  background-color: #0d39ab !important;
  color: #fff !important;
}
.contactDataFrgmt .street,
.contactDataFrgmt .plz {
  margin-right: 0.375rem;
}
.overviewFrgmt {
  margin-bottom: 0;
  padding-bottom: 0 !important;
}
.overviewFrgmt .overviewRow {
  margin-top: 1rem;
}
.overviewFrgmt .overviewRow .mb {
  margin-bottom: 0.375rem;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell {
  position: relative;
  background-color: #fff;
  padding: 1rem;
  border: 2px solid #d9e0f2;
  margin-bottom: 1rem;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell .sapUiBlockCellContent {
  margin-top: 0;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell h2 {
  min-height: 60px;
  margin-bottom: 0.5rem;
  color: #0d39ab !important;
  font-size: 1.5rem !important;
  line-height: 40px !important;
  vertical-align: bottom;
  padding-left: 40px;
  width: calc(100% - 110px);
  background-repeat: no-repeat;
  background-size: 75px 75px;
  background-position: 0 0;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.policyholder h2 {
  background-image: url('../img/policyholder.png');
  background-position: -20px -18px;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.policyholder .sapMFT {
  margin-bottom: 0.375rem;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.contractDetails h2 {
  padding-left: 50px;
  margin-bottom: 0;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.contractDetails[data-process="p1"] h2 {
  background-image: url('../img/neueVers.png');
  background-position: -8px -5px;
  background-size: 60px 60px;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.contractDetails h3 {
  display: block;
  font-weight: 600;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.contractDetails h4 {
  display: block;
  font-weight: 500;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.contractDetails .sapUiHLayout {
  position: relative;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.contractDetails .sapUiHLayout .sapMFT {
  display: inline-block;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell.contractDetails .sapUiHLayout .oevbsIco {
  position: absolute;
  top: 2px;
  left: 65px;
  margin: 0;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell:first-child {
  margin-left: 0.5rem;
}
.overviewFrgmt .overviewRow .sapUiBlockLayoutCell:last-child {
  margin-right: 0.5rem;
}
.overviewFrgmt .invElem {
  margin: 0 !important;
  padding: 0 !important;
  height: 1px !important;
  font-size: 1px !important;
  line-height: 1px !important;
}
.overviewFrgmt .invElem div,
.overviewFrgmt .invElem div:hover {
  border-color: white !important;
  display: none !important;
}
.consultationFrgmt {
  margin-top: 1rem;
}
.consultationFrgmt .consultationRow .sapUiBlockLayoutCell {
  position: relative;
  background-color: #fff;
  padding: 1rem;
  margin: 0 0.5rem;
  border: 2px solid #d9e0f2;
}
.consultationFrgmt .consultationRow .sapUiBlockLayoutCell h2 {
  margin-bottom: 0.5rem;
  color: #0d39ab !important;
  font-size: 2.25rem !important;
  line-height: 2.625rem !important;
  min-height: 100px;
  vertical-align: middle;
  padding-top: 0.5rem;
  padding-left: 110px;
  width: calc(100% - 110px);
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: 0 0;
  background-position-y: -10px;
}
.consultationFrgmt .consultationRow .sapUiBlockLayoutCell.berater h2 {
  background-image: url('../img/berater.png');
}
.consultationFrgmt .consultationRow .errMsg {
  margin-top: 1rem;
}
.consultationFrgmt .consultationRow .sapMCb {
  padding: 0 0 0 2rem;
}
.consultationFrgmt .consultationRow .sapMCb .sapMCbBg {
  left: 0;
}
.consultationFrgmt .sapMLnk {
  padding-top: 2px !important;
}
.completionFrgmt {
  position: relative;
  background-color: #fff;
  padding: 1rem;
  margin: 0 0.5rem;
  border: 2px solid #d9e0f2;
}
.completionFrgmt .completionRow .sapUiBlockLayoutCell h2 {
  margin-bottom: 0.5rem;
  color: #0d39ab !important;
  font-size: 2.25rem !important;
  line-height: 2.625rem !important;
  min-height: 100px;
  vertical-align: middle;
  padding-top: 0.5rem;
  padding-left: 110px;
  width: calc(100% - 110px);
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: 0 -5px;
}
.completionFrgmt .completionRow .sapUiBlockLayoutCell.contract h2 {
  background-image: url('../img/contractDetails.png');
}
.completionFrgmt .completionRowEnd {
  background-color: #0d39ab !important;
}
.completionFrgmt .completionRowEnd .sapUiBlockLayoutCell div {
  color: white !important;
}
.completionFrgmt .completionRowEnd .sapUiBlockLayoutCell label {
  color: white !important;
}
.completionFrgmt .sapMCb {
  padding: 0 0 0 2rem;
}
.completionFrgmt .sapMCb .sapMCbBg {
  left: 0;
}
.sendFrgmt {
  overflow: visible;
  padding: 1rem;
  background-color: #d9e0f2;
  margin: 1rem 0.5rem;
}
.sendFrgmt .sapUiBlockLayoutCell h2 {
  margin-bottom: 0.5rem;
  font-size: 2.25rem !important;
  color: #0d39ab !important;
  line-height: 2.625rem !important;
  min-height: 100px;
  vertical-align: middle;
  padding-top: 0.5rem;
  padding-left: 110px;
  width: calc(100% - 110px);
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: 0 0;
  background-position-y: -10px;
}
.sendFrgmt .sapUiBlockLayoutCell.police h2 {
  background-image: url('../img/police.png');
}
.sendFrgmt .errMsg {
  margin-top: 1rem;
}
.sendFrgmt .sapMCb {
  padding: 0 0 0 2rem;
}
.sendFrgmt .sapMCb .sapMCbBg {
  left: 0;
}
.sendFrgmt .floatLeft {
  float: left !important;
}
.sendFrgmt .sapMBtn {
  float: right;
}
.sendFrgmt .handICO {
  cursor: pointer !important;
  display: inline-block;
  background-image: url('../img/handBG.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
  margin-left: 5px;
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.sendFrgmt .handICO:hover {
  animation: shake 0.5s ease-in forwards;
}
.thanksFrgmt {
  overflow: visible;
  padding: 1rem;
  background-color: #d9e0f2;
  margin: 1rem 0.5rem;
}
.thanksFrgmt .sapUiBlockLayoutCell h2 {
  margin-bottom: 0.5rem;
  font-size: 2.25rem !important;
  color: #0d39ab !important;
  line-height: 2.625rem !important;
  min-height: 100px;
  vertical-align: middle;
  padding-top: 0.5rem;
  padding-left: 110px;
  width: calc(100% - 110px);
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: 0 0;
  background-position-y: -10px;
  background-image: url('../img/success.png');
}
.thanksFrgmt .sapMCb {
  padding: 0 0 0 2rem;
}
.thanksFrgmt .sapMCb .sapMCbBg {
  left: 0;
}
.thanksFrgmt .sapMBtn {
  float: left;
}
@font-face {
  font-family: "oevbs-iconfont";
  src: url("../fonts/oevbs-iconfont.eot");
  src: url("../fonts/oevbs-iconfont.eot?#iefix") format("embedded-opentype"), url("../fonts/oevbs-iconfont.woff2") format("woff2"), url("../fonts/oevbs-iconfont.woff") format("woff"), url("../fonts/oevbs-iconfont.ttf") format("truetype"), url("../fonts/oevbs-iconfont.svg#oevbs-iconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "oevbs-iconfont";
    src: url("../fonts/oevbs-iconfont.svg#oevbs-iconfont") format("svg");
  }
}
[data-icon]:before {
  content: attr(data-icon);
}
[data-icon]:before,
.fo-alert:before,
.fo-arrow-down:before,
.fo-arrow-left:before,
.fo-arrow-right:before,
.fo-arrow-up:before,
.fo-backward:before,
.fo-bar:before,
.fo-bullet:before,
.fo-bullet-arrow:before,
.fo-calculator:before,
.fo-calendar:before,
.fo-check:before,
.fo-clock:before,
.fo-customer:before,
.fo-delete:before,
.fo-dislike:before,
.fo-dot:before,
.fo-doublearrow-down:before,
.fo-doublearrow-up:before,
.fo-download:before,
.fo-exclamation:before,
.fo-forward:before,
.fo-id-card:before,
.fo-like:before,
.fo-mail:before,
.fo-memorize:before,
.fo-menu:before,
.fo-minus:before,
.fo-mobile-berater:before,
.fo-mobile-menue:before,
.fo-mobile-menue-aktiv:before,
.fo-mobile-menue-hoch:before,
.fo-mobile-menue-hoch-aktiv:before,
.fo-padlock:before,
.fo-padlock-open:before,
.fo-paper:before,
.fo-paper-blank:before,
.fo-pencil:before,
.fo-phone:before,
.fo-place:before,
.fo-play-pause:before,
.fo-plus:before,
.fo-print:before,
.fo-question:before,
.fo-reload:before,
.fo-search:before,
.fo-service-numbers:before,
.fo-speaker:before,
.fo-table-pagination:before,
.fo-trash-bin:before,
.fo-world:before {
  display: inline-block;
  font-family: "oevbs-iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  padding: 0 0 5px 1px !important;
}
.fo-alert:before {
  content: "\62";
}
.fo-arrow-down:before {
  content: "\4b";
}
.fo-arrow-left:before {
  content: "\4a";
}
.fo-arrow-right:before {
  content: "\71";
}
.fo-arrow-up:before {
  content: "\4c";
}
.fo-backward:before {
  content: "\7a";
}
.fo-bar:before {
  content: "\48";
}
.fo-bullet:before {
  content: "\45";
}
.fo-bullet-arrow:before {
  content: "\52";
}
.fo-calculator:before {
  content: "\6a";
}
.fo-calendar:before {
  content: "\64";
}
.fo-check:before {
  content: "\70";
}
.fo-clock:before {
  content: "\4f";
}
.fo-customer:before {
  content: "\6c";
}
.fo-delete:before {
  content: "\74";
}
.fo-dislike:before {
  content: "\61";
}
.fo-dot:before {
  content: "\4d";
}
.fo-doublearrow-down:before {
  content: "\53";
}
.fo-doublearrow-up:before {
  content: "\54";
}
.fo-download:before {
  content: "\42";
}
.fo-exclamation:before {
  content: "\41";
}
.fo-forward:before {
  content: "\43";
}
.fo-id-card:before {
  content: "\50";
}
.fo-like:before {
  content: "\73";
}
.fo-mail:before {
  content: "\46";
}
.fo-memorize:before {
  content: "\69";
}
.fo-menu:before {
  content: "\66";
}
.fo-minus:before {
  content: "\63";
}
.fo-mobile-berater:before {
  content: "\55";
}
.fo-mobile-menue:before {
  content: "\56";
}
.fo-mobile-menue-aktiv:before {
  content: "\57";
}
.fo-mobile-menue-hoch:before {
  content: "\58";
}
.fo-mobile-menue-hoch-aktiv:before {
  content: "\59";
}
.fo-padlock:before {
  content: "\6e";
}
.fo-padlock-open:before {
  content: "\6f";
}
.fo-paper:before {
  content: "\68";
}
.fo-paper-blank:before {
  content: "\67";
}
.fo-pencil:before {
  content: "\75";
}
.fo-phone:before {
  content: "\6d";
}
.fo-place:before {
  content: "\49";
}
.fo-play-pause:before {
  content: "\79";
}
.fo-plus:before {
  content: "\77";
}
.fo-print:before {
  content: "\44";
}
.fo-question:before {
  content: "\78";
}
.fo-reload:before {
  content: "\47";
}
.fo-search:before {
  content: "\65";
}
.fo-service-numbers:before {
  content: "\6b";
}
.fo-speaker:before {
  content: "\76";
}
.fo-table-pagination:before {
  content: "\72";
}
.fo-trash-bin:before {
  content: "\51";
}
.fo-world:before {
  content: "\4e";
}
.fo {
  display: inline-block;
  font-family: "oevbs-iconfont";
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif;
}
.fo:before {
  font-family: "oevbs-iconfont";
}
.fo-close:before {
  content: "t";
}
@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparrg-webfont.eot");
  src: url("../fonts/sparrg-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/sparrg-webfont.woff") format("woff"), url("../fonts/sparrg-webfont.ttf") format("truetype"), url("../fonts/sparrg-webfont.svg#sparkasse_rgregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparrgit-webfont.eot");
  src: url("../fonts/sparrgit-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/sparrgit-webfont.woff") format("woff"), url("../fonts/sparrgit-webfont.ttf") format("truetype"), url("../fonts/sparrgit-webfont.svg#sparkasse_rgitalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparlt-webfont.eot");
  src: url("../fonts/sparlt-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/sparlt-webfont.woff") format("woff"), url("../fonts/sparlt-webfont.ttf") format("truetype"), url("../fonts/sparlt-webfont.svg#sparkasse_ltregular") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparltit-webfont.eot");
  src: url("../fonts/sparltit-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/sparltit-webfont.woff") format("woff"), url("../fonts/sparltit-webfont.ttf") format("truetype"), url("../fonts/sparltit-webfont.svg#sparkasse_ltitalic") format("svg");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparbd-webfont.eot");
  src: url("../fonts/sparbd-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/sparbd-webfont.woff") format("woff"), url("../fonts/sparbd-webfont.ttf") format("truetype"), url("../fonts/sparbd-webfont.svg#sparkasse_rgbold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Sparkasse Web';
  src: url("../fonts/sparbdit-webfont.eot");
  src: url("../fonts/sparbdit-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/sparbdit-webfont.woff") format("woff"), url("../fonts/sparbdit-webfont.ttf") format("truetype"), url("../fonts/sparbdit-webfont.svg#sparkasse_rgbold_italic") format("svg");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "BrixSansBold";
  src: url("../fonts/BrixSansBold.eot");
  src: url("../fonts/BrixSansBold.eot?#iefix") format("embedded-opentype"), url("../fonts/BrixSansBold.woff2") format("woff2"), url("../fonts/BrixSansBold.woff") format("woff"), url("../fonts/BrixSansBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BrixSansLight";
  src: url("../fonts/BrixSansLight.eot");
  src: url("../fonts/BrixSansLight.eot?#iefix") format("embedded-opentype"), url("../fonts/BrixSansLight.woff2") format("woff2"), url("../fonts/BrixSansLight.woff") format("woff"), url("../fonts/BrixSansLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BrixSansRegular-Italic";
  src: url("../fonts/BrixSansRegular-Italic.eot");
  src: url("../fonts/BrixSansRegular-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/BrixSansRegular-Italic.woff2") format("woff2"), url("../fonts/BrixSansRegular-Italic.woff") format("woff"), url("../fonts/BrixSansRegular-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BrixSansRegular";
  src: url("../fonts/BrixSansRegular.eot");
  src: url("../fonts/BrixSansRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/BrixSansRegular.woff2") format("woff2"), url("../fonts/BrixSansRegular.woff") format("woff"), url("../fonts/BrixSansRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.msgWrapper {
  width: calc(100% - 0rem);
}
.msgWrapper .sapMMsgStrip {
  margin: 0 0 0.5rem 0;
}
.errorCtrl input,
.errorCtrl.sapMSlt {
  border: 1px solid #da291c;
}
.errorCtrl input:focus,
.errorCtrl.sapMSlt:focus {
  border-color: #da291c !important;
}
.errorCtrl.sapMSF form {
  border: 1px solid #da291c;
}
.errorCtrl.sapMSF form:focus {
  border-color: #da291c !important;
}
.errorCtrl.sapMSF form .sapMSFB {
  color: #da291c;
}
.errorCtrl.sapMSF input,
.errorCtrl.sapMSF:focus {
  border: none !important;
}
.errorCtrl bdi {
  padding-right: 1.5rem;
}
.errorCtrl bdi:after {
  content: "";
  background-image: url(../svg/inline_validierung_negativ.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.5rem;
  margin-top: 0;
}
.errorCtrl.sapMRb .sapMRbBOut {
  border-color: #da291c !important;
}
.errorCtrl.sapMRb bdi:after {
  display: none;
}
.errorCtrl.sapMCb .sapMCbBg {
  border-color: #da291c !important;
}
.sapUiHLayout > .errorCtrl bdi:after {
  margin-top: 0 !important;
}
.errMsg {
  margin-top: 0.25rem;
  color: #da291c !important;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}
.roadMap {
  width: 100%;
  margin-bottom: 0.5rem;
}
.roadMap span:before,
.roadMap span:after {
  display: none !important;
}
.roadMap .pointer {
  height: 40px;
  background: #5977c6;
  margin-right: 1px;
  z-index: 5;
  position: relative;
  color: #fff;
}
.roadMap .pointer:before {
  content: "";
  position: absolute;
  right: -19px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #5977c6;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.roadMap .pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.roadMap .pointer:first-child {
  margin-left: 0;
  background: #0d39ab;
}
.roadMap .pointer:first-child:after {
  border-left: 0;
}
.roadMap .pointer:first-child:before {
  border-left-color: #0d39ab;
}
.roadMap .pointer:nth-child(2) {
  z-index: 4;
}
.roadMap .pointer:nth-child(3) {
  z-index: 3;
}
.roadMap .pointer:last-child {
  z-index: 2;
}
.roadMap .pointer:last-child:before {
  border-left: 0;
}
.roadMap > div {
  flex-grow: 1 !important;
  display: inline-flex;
  height: 40px;
  background: #5977c6;
  margin-right: 1px;
  z-index: 5;
  position: relative;
  color: #fff;
}
.roadMap > div:before {
  content: "";
  position: absolute;
  right: -19px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #5977c6;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.roadMap > div:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.roadMap > div:first-child {
  margin-left: 0;
  background: #0d39ab;
}
.roadMap > div:first-child:after {
  border-left: 0;
}
.roadMap > div:first-child:before {
  border-left-color: #0d39ab;
}
.roadMap > div:nth-child(2) {
  z-index: 4;
}
.roadMap > div:nth-child(3) {
  z-index: 3;
}
.roadMap > div:last-child {
  z-index: 2;
}
.roadMap > div:last-child:before {
  border-left: 0;
}
.roadMap > div .sapMFT {
  color: inherit;
  text-align: center;
  vertical-align: middle;
  width: calc(100% - 20px);
  padding-left: 20px;
  line-height: 40px;
}
.roadMap > div .sapMFT span {
  cursor: inherit;
}
.roadMap > div .sapMFT .no {
  border: 2px solid #fff;
  width: 25px;
  padding-left: 2px;
  height: 25px;
  line-height: 22px;
  display: inline-block;
  border-radius: 15px;
  text-align: center;
}
.roadMap > div .sapMFT .title {
  margin-left: 5px;
}
@media (max-width: 780px) {
  .roadMap > div .sapMFT .title {
    display: none;
  }
}
@media (max-width: 420px) {
  .roadMap > div .sapMFT {
    font-size: 0.75rem !important;
  }
  .roadMap > div .sapMFT .no {
    width: 20px;
    height: 20px;
    line-height: 17px;
    border-radius: 20px;
  }
}
@media (min-width: 414px) {
  .roadMap[data-actualStep='webStepStart'] > div:first-child .title {
    display: inline-block;
  }
}
.roadMap[data-actualStep='webStepStart'] > div:first-child .sapMFT {
  cursor: pointer;
}
.roadMap[data-actualStep='webStepStart'] > div:first-child .sapMFT span {
  cursor: inherit;
}
.roadMap[data-actualStep='webStepOffer'] > div:nth-child(1),
.roadMap[data-actualStep='webStepOffer'] > div:nth-child(2) {
  background: #0d39ab;
}
.roadMap[data-actualStep='webStepOffer'] > div:nth-child(1) .sapMFT,
.roadMap[data-actualStep='webStepOffer'] > div:nth-child(2) .sapMFT {
  cursor: pointer;
}
.roadMap[data-actualStep='webStepOffer'] > div:nth-child(1):before,
.roadMap[data-actualStep='webStepOffer'] > div:nth-child(2):before {
  border-left-color: #0d39ab;
}
@media (min-width: 414px) {
  .roadMap[data-actualStep='webStepOffer'] > div:nth-child(2) .title {
    display: inline-block;
  }
}
.roadMap[data-actualStep='webStepRequest'] > div:nth-child(2),
.roadMap[data-actualStep='webStepRequest'] > div:nth-child(3) {
  background: #0d39ab;
}
.roadMap[data-actualStep='webStepRequest'] > div:nth-child(2) .sapMFT,
.roadMap[data-actualStep='webStepRequest'] > div:nth-child(3) .sapMFT {
  cursor: pointer;
}
.roadMap[data-actualStep='webStepRequest'] > div:nth-child(2):before,
.roadMap[data-actualStep='webStepRequest'] > div:nth-child(3):before {
  border-left-color: #0d39ab;
}
@media (min-width: 414px) {
  .roadMap[data-actualStep='webStepRequest'] > div:nth-child(3) .title {
    display: inline-block;
  }
}
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(2),
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(3),
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(4) {
  background: #0d39ab;
}
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(2) .sapMFT,
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(3) .sapMFT,
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(4) .sapMFT {
  cursor: pointer;
}
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(2):before,
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(3):before,
.roadMap[data-actualStep='webStepConclusion'] > div:nth-child(4):before {
  border-left-color: #0d39ab;
}
@media (min-width: 414px) {
  .roadMap[data-actualStep='webStepConclusion'] > div:last-child .title {
    display: inline-block;
  }
}
.maintenanceV .sapUiBlockLayoutCell {
  margin-right: 1rem;
}
.maintenanceV .sapUiBlockLayoutCell:first-child {
  text-align: center;
}
.maintenanceV .sapUiBlockLayoutCell:last-child {
  margin-right: 0;
}
.maintenanceV .sapMFT {
  margin-top: 2rem;
  font-size: 1.5rem;
}
.propertiesBitAutoComplete {
  background: transparent;
  position: relative;
  display: inline-block;
  vertical-align: top;
  outline: none;
  padding: 0.25rem 0;
  border-radius: 0;
  height: 2.5rem;
  width: 100%;
  margin-top: 0.25rem;
}
.propertiesBitAutoCompleteInner {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  font-size: 0.875rem;
  line-height: normal;
  padding: 0 0.75rem;
  height: 100%;
  box-sizing: border-box;
}
.bitAutoComplete {
  background: transparent !important;
  position: relative !important;
  display: inline-block !important;
  vertical-align: top !important;
  outline: none !important;
  padding: 0.25rem 0 !important;
  border-radius: 0 !important;
  height: 2.5rem !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
}
.bitAutoComplete .sapUiTfCombo {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
}
.bitAutoComplete .sapUiTfInner {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  font-size: 0.875rem !important;
  line-height: normal !important;
  padding: 0 0.75rem !important;
  height: 100% !important;
  box-sizing: border-box !important;
}
.bitAutoComplete.error,
.bitAutoComplete.errorCtrl {
  border-color: #d62018;
}
.sapUiLbx .sapUiLbxI {
  height: 3rem;
}
.sapUiLbx .sapUiLbxI .sapUiLbxITxt {
  font-size: 0.875rem;
}
.blockSize {
  background: #0d39ab;
  color: white;
  width: 100%;
  margin: 0 auto;
  max-width: 830px;
  min-width: 160px;
}
.lblSettings {
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.5rem;
  vertical-align: top;
  display: block;
  max-width: 100%;
}
.blockWrap {
  width: calc(33% - 20px);
  min-width: calc(190px - 25px);
  margin: 0px 10px;
}
.blockWrap .sapMLabel {
  color: #fff !important;
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  display: block !important;
  max-width: 100% !important;
}
.dataFrgmt {
  background: #0d39ab;
  color: white;
  width: 100%;
  margin: 0 auto;
  max-width: 830px;
  min-width: 160px;
  padding: 10px 0 10px 10px;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;
}
.dataFrgmt .birthdate {
  width: calc(33% - 20px);
  min-width: calc(190px - 25px);
  margin: 0px 10px;
}
.dataFrgmt .birthdate .sapMLabel {
  color: #fff !important;
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  display: block !important;
  max-width: 100% !important;
}
.dataFrgmt .birthdate .sapMInput {
  width: 125px !important;
}
.dataFrgmt .homeSpace {
  width: calc(33% - 20px);
  min-width: calc(190px - 25px);
  margin: 0px 10px;
}
.dataFrgmt .homeSpace .sapMLabel {
  color: #fff !important;
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  display: block !important;
  max-width: 100% !important;
}
.dataFrgmt .homeSpace .sapMLabel {
  display: inline-block !important;
}
.dataFrgmt .homeSpace .cbHomespace {
  margin: 0 0 0 10px;
  background-color: #0d39ab;
  background-image: url('../img/decline3.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 22px;
  width: 22px;
  display: inline-block;
  cursor: pointer !important;
}
.dataFrgmt .homeSpace .cbHomespace[data-enclosed="X"] {
  background-image: url('../img/check.png');
}
.dataFrgmt .homeSpace .wrapper {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.dataFrgmt .homeSpace .wrapper .qm {
  width: 55px !important;
  margin: 0 5px 0 0;
}
.dataFrgmt .homeSpace .wrapper .zip {
  width: 85px !important;
}
.dataFrgmt .stocknumber {
  width: calc(33% - 20px);
  min-width: calc(190px - 25px);
  margin: 0px 10px;
}
.dataFrgmt .stocknumber .sapMLabel {
  color: #fff !important;
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  display: block !important;
  max-width: 100% !important;
}
.dataFrgmt .stocknumber .sapMSlt,
.dataFrgmt .stocknumber .sapMInput {
  width: 125px !important;
}
.dataFrgmt .term {
  width: calc(33% - 20px);
  min-width: calc(190px - 25px);
  margin: 0px 10px;
}
.dataFrgmt .term .sapMLabel {
  color: #fff !important;
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  display: block !important;
  max-width: 100% !important;
}
.dataFrgmt .period {
  width: calc(33% - 20px);
  min-width: calc(190px - 25px);
  margin: 0px 10px;
}
.dataFrgmt .period .sapMLabel {
  color: #fff !important;
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  display: block !important;
  max-width: 100% !important;
}
.dataFrgmt .beginn {
  width: calc(33% - 20px);
  min-width: calc(190px - 25px);
  margin: 0px 10px;
}
.dataFrgmt .beginn .sapMLabel {
  color: #fff !important;
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  display: block !important;
  max-width: 100% !important;
}
.dataFrgmt .sapMBtn:hover > .sapMBtnHoverable .sapMBtnIcon {
  box-shadow: none !important;
}
.blockSizeOnline {
  color: white;
  width: calc(100% -1px);
  margin: 0 auto;
  max-width: 100%;
  min-width: 50%;
}
.lblSettingsOnline {
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.5rem;
  vertical-align: super;
  width: calc(50% - 30px);
  margin-left: 2px;
}
.blockWrapOnline {
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
}
.blockWrapOnline .sapMLabel {
  color: #fff !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  vertical-align: super !important;
  width: calc(50% - 30px) !important;
  margin-left: 2px !important;
}
.blockPeriodTerm {
  background: #0d39ab;
  color: white;
  width: 100%;
  margin: 0 auto;
  max-width: 830px;
  min-width: 160px;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;
}
.blockPeriodTerm .term {
  width: 135px !important;
  margin: 3px;
  border-color: white;
}
.blockPeriodTerm .term.sapMSlt > .sapMSltLabel {
  color: white;
  background: #0d39ab;
  text-overflow: unset !important;
  width: 120% !important;
}
.blockPeriodTerm .term .sapMSltArrow {
  color: white;
}
.blockPeriodTerm .period {
  width: 135px !important;
  margin: 3px;
  border-color: white;
}
.blockPeriodTerm .period.sapMSlt > .sapMSltLabel {
  color: white;
  background: #0d39ab;
}
.blockPeriodTerm .period .sapMSltArrow {
  color: white;
}
.blockPeriodTerm .period .sapMSlt > .sapMSltLabel {
  text-overflow: unset !important;
}
.blockPeriodTerm .beginn {
  width: 135px !important;
  margin: 3px;
  border-color: white;
}
.blockPeriodTerm .beginn.sapMSlt > .sapMSltLabel {
  color: white;
  background: #0d39ab;
}
.blockPeriodTerm .beginn .sapMSltArrow {
  color: white;
}
.blockPeriodTerm .beginn .sapMSlt > .sapMSltLabel {
  text-overflow: unset !important;
}
.blockBegin {
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.blockBegin .sapMLabel {
  color: #fff !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  vertical-align: super !important;
  width: calc(50% - 30px) !important;
  margin-left: 2px !important;
}
.blockBegin .sapMLabel {
  width: 172px !important;
}
.blockBegin .begin {
  width: 165px !important;
}
.blockBegin .begin .sapMInputBaseInner {
  height: 37px;
  color: white;
  background: #0d39ab;
  border-color: white;
}
.blockBegin .begin .sapMInputValHelpInner {
  color: white;
}
.blockBack {
  background: #0d39ab;
  color: white;
  width: 100%;
  margin: 0 auto;
  max-width: 830px;
  min-width: 160px;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;
}
.blockBack .backBtn {
  height: 40px;
  margin-top: 20px !important;
  margin-right: 3px;
  left: 1px;
  right: 10px;
  bottom: 14px;
}
.blockBack .backBtn .sapMBtnInner {
  border-color: #fff !important;
  background: #0d39ab !important;
  border-radius: 30px;
  color: #fff;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  font-size: 0.9rem !important;
}
.blockBack .backBtn .sapMBtnContent {
  height: 40px;
  line-height: 40px;
}
.blockBack .backBtn:hover .sapMBtnInner {
  border-color: #0d39ab !important;
  background: #fff !important;
  color: #0d39ab;
}
.blockBack a.iconPdf {
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif;
  border: 1px solid #fff !important;
  background: #0d39ab !important;
  border-radius: 30px;
  color: #fff !important;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  font-size: 0.9rem;
  padding: 0 10px;
  margin-top: 11px;
}
.blockBack a.iconPdf:focus {
  border-color: #97d700 !important;
}
.blockBack a.iconPdf:hover {
  background: #fff !important;
  color: #0d39ab;
  border-color: #97d700 !important;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
  text-decoration: none !important;
}
.blockBack .editOffer {
  margin-right: 10px !important;
}
.questionsFrgmt {
  color: white;
  width: calc(100% -1px);
  margin: 0 auto;
  max-width: 100%;
  min-width: 50%;
  padding-bottom: 7px;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;
}
.questionsFrgmt .oevbsIco {
  margin-left: 5px;
}
.questionsFrgmt .age {
  margin-top: 7px;
  background: linear-gradient(to right, #0d39ab, #3358b9);
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
}
.questionsFrgmt .age .sapMLabel {
  color: #fff !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  vertical-align: super !important;
  width: calc(50% - 30px) !important;
  margin-left: 2px !important;
}
.questionsFrgmt .age .ageInp {
  width: 100px !important;
  vertical-align: text-bottom;
  top: 10px;
}
.questionsFrgmt .age .ageInp .sapMSltArrow {
  background: rgba(222, 222, 222, 0.5);
}
.questionsFrgmt .status {
  margin-top: 7px;
  background: linear-gradient(to right, #0d39ab, #3358b9);
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
}
.questionsFrgmt .status .sapMLabel {
  color: #fff !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  vertical-align: super !important;
  width: calc(50% - 30px) !important;
  margin-left: 2px !important;
}
.questionsFrgmt .status .statusInp {
  width: 100px !important;
  vertical-align: text-bottom;
  top: 10px;
}
.questionsFrgmt .status .statusInp .sapMSltArrow {
  background: rgba(222, 222, 222, 0.5);
}
.questionsFrgmt .homeStatus {
  margin-top: 7px;
  background: linear-gradient(to right, #0d39ab, #3358b9);
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
}
.questionsFrgmt .homeStatus .sapMLabel {
  color: #fff !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  vertical-align: super !important;
  width: calc(50% - 30px) !important;
  margin-left: 2px !important;
}
.questionsFrgmt .homeStatus .homeStatusInp {
  width: 101px !important;
  vertical-align: text-bottom;
  top: 10px;
}
.questionsFrgmt .homeStatus .homeStatusInp .sapMSltArrow {
  background: rgba(222, 222, 222, 0.5);
}
.questionsFrgmt .homeSpace {
  margin-top: 7px;
  background: linear-gradient(to right, #0d39ab, #3358b9);
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
}
.questionsFrgmt .homeSpace .sapMLabel {
  color: #fff !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  vertical-align: super !important;
  width: calc(50% - 30px) !important;
  margin-left: 2px !important;
}
.questionsFrgmt .homeSpace .qmInp {
  width: 60px !important;
  vertical-align: text-bottom;
  top: 10px;
}
.questionsFrgmt .homeZip {
  margin-top: 7px;
  background: linear-gradient(to right, #0d39ab, #3358b9);
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
}
.questionsFrgmt .homeZip .sapMLabel {
  color: #fff !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  vertical-align: super !important;
  width: calc(50% - 30px) !important;
  margin-left: 2px !important;
}
.questionsFrgmt .homeZip .zipInp {
  width: 101px !important;
  vertical-align: text-bottom;
  top: 10px;
}
.questionsFrgmt .calculateBlock {
  margin-top: 7px;
  background: linear-gradient(to right, #0d39ab, #3358b9);
  width: calc(100% - 2px) !important;
  min-width: calc(100% - 2px) !important;
  position: relative;
  display: block;
  height: 68px;
}
.questionsFrgmt .calculateBlock .sapMLabel {
  color: #fff !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  vertical-align: super !important;
  width: calc(50% - 30px) !important;
  margin-left: 2px !important;
}
.questionsFrgmt .calculateBlock .handICO {
  cursor: pointer !important;
  display: inline-block;
  background-image: url('../img/handBG.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
  margin-left: 5px;
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.questionsFrgmt .calculateBlock .handICO:hover {
  animation: shake 0.5s ease-in forwards;
}
.questionsFrgmt .calculateBlock .nextBtn {
  height: 60px;
  margin-top: 10px;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
.questionsFrgmt .calculateBlock .nextBtn .sapMBtnInner {
  border-color: #fff !important;
  background: #0d39ab !important;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  font-size: 1.3rem;
}
.questionsFrgmt .calculateBlock .nextBtn .sapMBtnContent {
  height: 50px;
  line-height: 50px;
}
.questionsFrgmt .calculateBlock .nextBtn:hover .sapMBtnInner {
  border-color: #0d39ab !important;
  background: #fff !important;
  color: #da291c;
}
.questionsFrgmt .calculateBlock .nextBtn:focus .sapMBtnInner {
  border-color: #97d700 !important;
}
.questionsFrgmt .fadeIn {
  display: inline-block;
  opacity: 0;
  -webkit-animation: fade 0.3s ease-in 0.3s forwards;
  animation: fade 0.3s ease-in 0.3s forwards;
}
.questionsFrgmt .sapMSltWithArrow > .sapMSltLabel,
.questionsFrgmt .sapMSltWithArrow .sapMSelectListItemBase {
  padding: 0 0 0 0.3rem !important;
}
.questionsFrgmt .sapMInputBaseInner {
  color: #5cb85c;
}
.questionsFrgmt .sapMSlt > .sapMSltLabel {
  color: #5cb85c !important;
}
.questionsFrgmt .sapMInputBaseInner:not(.sapMInputBaseReadonlyInner):not(.sapMInputBaseStateErrorInner):not(.sapMInputBaseDisabledInner):focus {
  background: #fafafa;
}
@-webkit-keyframes maxi {
  to {
    width: calc( 100% - 60px);
  }
  from {
    width: 60px;
  }
}
@keyframes maxi {
  to {
    width: calc( 100% - 60px);
  }
  from {
    width: 60px;
  }
}
@-webkit-keyframes mini {
  from {
    width: calc( 100% - 60px);
  }
  to {
    width: 60px;
  }
}
@keyframes mini {
  from {
    width: calc( 100% - 60px);
  }
  to {
    width: 60px;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.productsFrgmt {
  width: 100%;
}
.productsFrgmt:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
}
.productsFrgmt .prodWrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 840px;
  min-width: 320px;
  display: block;
}
.productsFrgmt .prodWrapper .swipeWrapper {
  background: #e3ecf9;
  width: 100%;
  position: relative;
}
.productsFrgmt .prodWrapper .swipeWrapper .checkfield {
  background-color: #0d39ab;
  background-image: url('../img/decline3.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60px;
  width: 60px;
  display: inline-block;
  float: left;
  cursor: pointer !important;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe {
  width: calc(100% - 60px);
  height: 60px;
  display: inline-block;
  float: left;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .triangle {
  width: 0;
  height: 0;
  position: absolute;
  z-index: 1;
  border-left: 20px solid #3358b9;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  left: 120px;
  top: 0px;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .sapUiIcon {
  font-weight: bold;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .sapUiIcon:focus {
  outline: none !important;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .sapUiIcon.moreInfoLink {
  font-size: 26px !important;
  color: #0d39ab;
  padding: 5px;
  border-radius: 15px;
  cursor: help !important;
  margin-left: 20px;
  position: absolute;
  right: 5px;
  top: 10px;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .swiper {
  background: linear-gradient(to right, #0d39ab, #3358b9);
  overflow: hidden;
  height: 60px;
  width: 60px;
  min-width: 60px;
  display: inline-block;
  position: absolute;
  z-index: 2;
  -webkit-animation: mini 0.5s ease-in forwards;
  animation: mini 0.5s ease-in forwards;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .swiper .title {
  color: #fff !important;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .swiper .subtitle {
  color: #fff !important;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .swiper .whiteIcon {
  color: #fff !important;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .swiper .sapUiIcon.close {
  cursor: pointer;
  color: #fff !important;
  font-size: 2rem;
  z-index: 2;
  position: absolute;
  right: 25px;
  top: 20px;
  display: none;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .swiper .sapUiIcon.moreInfoLink {
  font-size: 26px !important;
  color: #fff !important;
  position: absolute;
  right: 5px;
  top: 10px;
  display: none;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .img {
  background-size: contain;
  background-color: transparent;
  background-position: center center;
  height: 60px;
  width: 60px;
  position: absolute;
  z-index: 3;
  cursor: pointer !important;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .title {
  margin-left: 85px;
  margin-right: 2px;
  display: inline-block;
  line-height: 60px;
  vertical-align: middle;
  height: 60px;
  font-size: 1.3rem !important;
  cursor: pointer !important;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .title.wSubtitle {
  padding-top: 20px;
  line-height: unset;
  vertical-align: unset;
  height: unset;
}
.productsFrgmt .prodWrapper .swipeWrapper .prodSwipe .subtitle {
  display: block;
  font-size: 0.7rem !important;
}
.productsFrgmt .prodWrapper .disabledTxt {
  position: absolute;
  left: 125px;
  top: 40px;
  right: 0;
  font-size: 0.8rem !important;
}
.productsFrgmt .prodWrapper[data-type="phv"] .img {
  background-image: url('../img/products/white/ph.png');
}
.productsFrgmt .prodWrapper[data-type="ds"] .img {
  background-image: url('../img/products/white/ds.png');
}
.productsFrgmt .prodWrapper[data-type="unf"] .img {
  background-image: url('../img/products/white/un.png');
}
.productsFrgmt .prodWrapper[data-type="hr"] .img {
  background-image: url('../img/products/white/hr.png');
}
.productsFrgmt .prodWrapper[data-type="gl"] .img {
  background-image: url('../img/products/white/gl.png');
}
.productsFrgmt .prodWrapper[data-checked="X"] .swipeWrapper .checkfield {
  background-image: url('../img/check.png') !important;
}
.productsFrgmt .prodWrapper[data-checked="X"] .swipeWrapper .swiper {
  width: calc( 100% - 60px);
  -webkit-animation: maxi 0.5s ease-in forwards;
  animation: maxi 0.5s ease-in forwards;
}
.productsFrgmt .prodWrapper[data-checked="X"] .swipeWrapper .swiper .sapUiIcon.close {
  opacity: 0;
  -webkit-animation: fade 0.4s ease-in 0.4s forwards;
  animation: fade 0.4s ease-in 0.4s forwards;
}
.productsFrgmt .prodWrapper[data-checked="X"] .swipeWrapper .swiper .sapUiIcon.moreInfoLink {
  display: inline-block;
  opacity: 0;
  -webkit-animation: fade 0.4s ease-in 0.4s forwards;
  animation: fade 0.4s ease-in 0.4s forwards;
}
.productsFrgmt .prodWrapper[data-disabled="X"] .checkfield {
  opacity: 0.8;
  background-image: url('../img/decline3.png');
}
.productsFrgmt .prodWrapper[data-disabled="X"] .img {
  opacity: 0.8;
}
.productsFrgmt .prodWrapper[data-disabled="X"] .swiper {
  opacity: 0.8;
  animation: none !important;
}
.productsFrgmt .prodWrapper[data-disabled="X"] .triangle {
  display: none !important;
}
.productsFrgmt .prodWrapper .detailInformation {
  background-color: #d9e0f2;
  width: calc(100% - 20px);
  padding: 10px 10px 0 10px;
  margin-top: -7px;
  display: none;
}
.productsFrgmt .prodWrapper .detailInformation .polprHighlights .sapMFT {
  font-size: 1.15rem !important;
  vertical-align: middle;
}
.productsFrgmt .prodWrapper .detailInformation .polprHighlights .sapMFT .fo-like {
  font-size: 0.85rem;
  color: #0d39ab !important;
  margin-right: 5px;
}
.productsFrgmt .prodWrapper .detailInformation .polprHighlights .sapUiVltCell {
  margin-bottom: 5px;
}
.productsFrgmt .prodWrapper .detailInformation .polprHighlightsTitle {
  width: 100%;
}
.productsFrgmt .prodWrapper .detailInformation .polprHighlightsTitle .sapMFT {
  font-size: 1.15rem !important;
  vertical-align: middle;
  overflow: hidden;
}
.productsFrgmt .prodWrapper .detailInformation .polprHighlightsTitle .sapUiVltCell {
  margin-bottom: 10px;
}
.productsFrgmt .prodWrapper .detailInformation .polprDetails {
  margin: 5px 0 10px 0;
}
.oevbsIco[data-atbottom="X"] {
  bottom: -2px;
  position: absolute;
}
.priceFrgmt {
  display: inline-block;
  width: 100%;
}
.priceFrgmt .priceHand {
  display: none;
}
.priceFrgmt .blockPrice {
  margin: 0 auto;
  width: 100%;
  max-width: 840px;
  min-width: 320px;
  display: block;
  height: 68px;
  position: relative;
  background: #0d39ab;
}
.priceFrgmt .blockPrice .handICO {
  cursor: pointer !important;
  display: inline-block;
  background-image: url('../img/handBG.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
  margin-left: 5px;
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.priceFrgmt .blockPrice .handICO:hover {
  animation: shake 0.5s ease-in forwards;
}
.priceFrgmt .blockPrice .priceBlock {
  position: absolute;
  left: 185px;
  top: 10px;
  line-height: 2rem;
  display: inline-block;
}
.priceFrgmt .blockPrice .priceBlock strong {
  color: #fff;
  font-size: 1.75rem;
  display: block;
}
.priceFrgmt .blockPrice .priceBlock span {
  color: #fff;
  font-size: 1.5rem;
  display: block;
}
.priceFrgmt .blockPrice .priceBlock span span {
  text-decoration: line-through;
  display: inline;
}
.priceFrgmt .blockPrice .priceBlockOnline {
  position: absolute;
  left: 75px;
  top: 10px;
  line-height: 2rem;
  display: inline-block;
}
.priceFrgmt .blockPrice .priceBlockOnline strong {
  color: #fff !important;
  font-size: 1.75rem;
  display: block;
}
.priceFrgmt .blockPrice .priceBlockOnline span {
  color: #fff !important;
  font-size: 1.5rem;
  display: block;
}
.priceFrgmt .blockPrice .priceBlockOnline span span {
  text-decoration: line-through;
  display: inline;
}
.priceFrgmt .blockPrice .nextBtn {
  height: 60px;
  margin-top: 10px;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
.priceFrgmt .blockPrice .nextBtn .sapMBtnInner {
  border-color: #fff !important;
  background: #0d39ab !important;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  font-size: 1.3rem;
}
.priceFrgmt .blockPrice .nextBtn .sapMBtnContent {
  height: 50px;
  line-height: 50px;
}
.priceFrgmt .blockPrice .nextBtn:hover .sapMBtnInner {
  border-color: #0d39ab !important;
  background: #fff !important;
  color: #da291c;
}
.priceFrgmt .blockPrice .nextBtn:focus .sapMBtnInner {
  border-color: #97d700 !important;
}
@media (min-width: 880px) {
  .priceFrgmt .priceHand {
    display: inline-block;
    background-image: url('../img/handBG.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
    margin-left: 20px;
    width: 150px;
    height: 150px;
    position: fixed;
  }
  .priceFrgmt .priceHand strong {
    font-size: 1rem;
    position: absolute;
    left: 36px;
    bottom: 20px;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
    transform: rotate(-8deg);
    color: #0d39ab;
  }
  .priceFrgmt .priceHand span {
    font-size: 1rem;
    text-decoration: line-through;
    color: #d62018;
    position: absolute;
    top: 80px;
    left: 35px;
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
}
.policyholderTray {
  width: 100%;
}
.policyholderTray .tray {
  width: 100%;
  max-width: 840px;
  min-width: 320px;
}
@media (min-width: 1080px) {
  .dataFrgmt {
    display: inline-block !important;
    margin: 0 20px 0 5px;
    width: 185px;
    position: fixed;
    left: 0;
    padding: 10px;
  }
  .blsk .dataFrgmt {
    position: absolute;
    top: 0;
    width: 180px;
  }
  .productsFrgmt {
    margin: 0 0 0 210px;
    float: left;
    width: 840px;
  }
  .policyholderTray {
    margin: 0 0 0 210px;
    float: left;
  }
  .blockPrice {
    margin: 0 0 0 210px !important;
    width: 840px;
  }
  .questionsFrgmt {
    margin: 0 20px -15px 0px;
    width: 50%;
    left: 0;
  }
  .blockBtn {
    color: white;
    width: calc(100% -1px);
    margin: 0 auto;
    max-width: 100%;
    min-width: 50%;
    height: 68px;
    position: absolute !important;
    display: block !important;
  }
}
.blockBtn {
  width: calc(50% - 2px);
  max-width: 840px;
  min-width: 335px;
  height: 68px;
  position: relative;
  display: block !important;
  background: #0d39ab;
}
.blockBtn .nextBtn {
  height: 60px;
  margin-top: 10px;
  position: absolute;
  right: 15px;
}
.blockBtn .nextBtn .sapMBtnInner {
  border-color: #fff !important;
  background: #0d39ab !important;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  font-size: 1.3rem;
}
.blockBtn .nextBtn .sapMBtnContent {
  height: 50px;
  line-height: 50px;
}
.blockBtn .nextBtn:hover .sapMBtnInner {
  border-color: #0d39ab !important;
  background: #fff !important;
  color: #da291c;
}
.blockBtn .nextBtn:focus .sapMBtnInner {
  border-color: #97d700 !important;
}
.blockBtn .handICO {
  display: inline-block;
  background-image: url('../img/handBG.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 15px;
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.blockBtn .handICO:hover {
  animation: shake 0.5s ease-in forwards;
}
@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}
#visBody {
  background: #fff;
  color: #3c3c3b;
  padding: 0;
  margin: 0;
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-weight: 200;
  font-style: normal;
  line-height: 1.25rem;
  position: relative;
  cursor: default;
  width: 100%;
  /* overflow-y: overlay; 2.Balken halb verstecken */
  min-height: 500px;
}
#visBody.sapUiBody {
  background: #fff;
  color: #3c3c3b;
  padding: 0;
  margin: 0;
  font-family: "BrixSansRegular", "Helvetica", Helvetica, Arial, sans-serif !important;
  font-weight: 200;
  font-style: normal;
  line-height: 1.25rem;
  position: relative;
  cursor: default;
  width: 100%;
  /* overflow-y: overlay; 2.Balken halb verstecken */
}
#visBody.loading #content {
  visibility: hidden;
}
#visBody.loading #init {
  display: block;
  font-size: 2.25rem;
  line-height: 2.625rem;
  color: #0d39ab;
  margin-bottom: 0.938rem;
}
@media (min-width: 320px) and (max-width: 480px) {
  #visBody.loading #init {
    font-size: 1.75rem !important;
    line-height: 1.75rem !important;
  }
}
#visBody.loading #init span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
#visBody.loading #init span:after,
#visBody.loading #init span:before {
  display: none;
  content: "";
}
#visBody.loading #init span:nth-child(2) {
  animation-delay: .2s;
}
#visBody.loading #init span:nth-child(3) {
  animation-delay: .4s;
}
#visBody #init {
  display: none;
}
#visBody span,
#visBody table {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
#visBody .sapUiBlockLayoutCell.wHL h2 {
  font-size: 2.25rem !important;
  line-height: 2.625rem !important;
  color: #0d39ab !important;
  margin-bottom: 0.938rem !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  #visBody .sapUiBlockLayoutCell.wHL h2 {
    font-size: 1.75rem !important;
    line-height: 1.75rem !important;
  }
}
#visBody .sapMFT .wHL h3 {
  font-size: 2.25rem;
  line-height: 2.625rem;
  color: #0d39ab;
  margin-bottom: 0.938rem;
}
@media (min-width: 320px) and (max-width: 480px) {
  #visBody .sapMFT .wHL h3 {
    font-size: 1.75rem !important;
    line-height: 1.75rem !important;
  }
}
#visBody .fo-question {
  cursor: help !important;
}
#visBody .curr {
  font-size: 1.25rem;
}
#visBody .curr:after {
  content: ' €';
  display: inline;
}
#visBody .fleft {
  float: left;
}
#visBody .fright {
  float: right;
}
#visBody .dIF {
  display: inline-flex !important;
}
#visBody .version {
  display: block;
  text-align: center;
  width: 100%;
  color: #3c3c3b;
  font-size: 1rem;
}
#visBody .w100 {
  width: 100% !important;
}
#visBody .h100 {
  height: 100% !important;
}
#visBody .w40rem {
  width: 40rem !important;
}
#visBody .w39rem {
  width: 39rem !important;
}
#visBody .w38rem {
  width: 38rem !important;
}
#visBody .w37rem {
  width: 37rem !important;
}
#visBody .w36rem {
  width: 36rem !important;
}
#visBody .w35rem {
  width: 35rem !important;
}
#visBody .w34rem {
  width: 34rem !important;
}
#visBody .w33rem {
  width: 33rem !important;
}
#visBody .w32rem {
  width: 32rem !important;
}
#visBody .w31rem {
  width: 31rem !important;
}
#visBody .w30rem {
  width: 30rem !important;
}
#visBody .w29rem {
  width: 29rem !important;
}
#visBody .w28rem {
  width: 28rem !important;
}
#visBody .w27rem {
  width: 27rem !important;
}
#visBody .w26rem {
  width: 26rem !important;
}
#visBody .w25rem {
  width: 25rem !important;
}
#visBody .w24rem {
  width: 24rem !important;
}
#visBody .w23rem {
  width: 23rem !important;
}
#visBody .w22rem {
  width: 22rem !important;
}
#visBody .w21rem {
  width: 21rem !important;
}
#visBody .w20rem {
  width: 20rem !important;
}
#visBody .w19rem {
  width: 19rem !important;
}
#visBody .w18rem {
  width: 18rem !important;
}
#visBody .w17rem {
  width: 17rem !important;
}
#visBody .w16rem {
  width: 16rem !important;
}
#visBody .w15rem {
  width: 15rem !important;
}
#visBody .w14rem {
  width: 14rem !important;
}
#visBody .w13rem {
  width: 13rem !important;
}
#visBody .w12rem {
  width: 12rem !important;
}
#visBody .w11rem {
  width: 11rem !important;
}
#visBody .w10rem {
  width: 10rem !important;
}
#visBody .w9rem {
  width: 9rem !important;
}
#visBody .w8rem {
  width: 8rem !important;
}
#visBody .w7rem {
  width: 7rem !important;
}
#visBody .w6rem {
  width: 6rem !important;
}
#visBody .w5rem {
  width: 5rem !important;
}
#visBody .w4rem {
  width: 4rem !important;
}
#visBody .w3rem {
  width: 3rem !important;
}
#visBody .w2rem {
  width: 2rem !important;
}
#visBody .w1rem {
  width: 1rem !important;
}
#visBody .flexGrow {
  flex-grow: 1;
}
#visBody .mr05rem {
  margin-right: 0.5rem;
}
#visBody .mr1rem {
  margin-right: 1rem;
}
#visBody .mb1rem {
  margin-bottom: 1rem;
}
#visBody .noBorder {
  border: none !important;
}
#visBody .sapUiLocalBusyIndicator,
#visBody .sapUiBusyIndicator,
#visBody .sapUiBLy {
  z-index: 200 !important;
}
#visBody .sapMDialogPhone {
  z-index: 220 !important;
}
#visBody .sapUiBLy {
  background-color: #eee !important;
}
#visBody .sap-ui-blocklayer-popup {
  z-index: 199 !important;
  background-color: transparent;
}
#visBody #sapUiBusyIndicator,
#visBody .sapUiLocalBusy {
  z-index: 201 !important;
}
#visBody #sapUiBusyIndicator .sapUiLocalBusyIndicator,
#visBody .sapUiLocalBusy .sapUiLocalBusyIndicator {
  z-index: 201 !important;
}
#visBody #sapUiBusyIndicator .sapUiLocalBusyIndicator .sapUiLocalBusyIndicatorAnimation,
#visBody .sapUiLocalBusy .sapUiLocalBusyIndicator .sapUiLocalBusyIndicatorAnimation {
  background: url('../img/loading-oevbs-s.gif') no-repeat;
  background-size: 100% 100%;
  width: 50px !important;
  height: 50px !important;
  left: calc(100% / 2 - 25px) !important;
  top: calc(100% / 2 - 25px) !important;
  z-index: 201 !important;
  position: fixed;
}
#visBody #sapUiBusyIndicator .sapUiLocalBusyIndicator .sapUiLocalBusyIndicatorAnimation > div,
#visBody .sapUiLocalBusy .sapUiLocalBusyIndicator .sapUiLocalBusyIndicatorAnimation > div {
  display: none !important;
}
#visBody .btnMR .sapMBtn {
  margin-right: 0.5rem;
}
#visBody button.sapMBtn,
#visBody button:not(.dropdown).sapMBtn {
  outline: 0;
}
#visBody button.sapMBtn:hover > .sapMBtnHoverable,
#visBody button:not(.dropdown).sapMBtn:hover > .sapMBtnHoverable {
  background: #da291c;
  color: #fff !important;
  text-shadow: none;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
#visBody button.sapMBtn:hover > .sapMBtnHoverable span,
#visBody button:not(.dropdown).sapMBtn:hover > .sapMBtnHoverable span {
  color: inherit;
}
#visBody button.sapMBtn:hover > .sapMBtnHoverable.sapMBtnTransparent,
#visBody button:not(.dropdown).sapMBtn:hover > .sapMBtnHoverable.sapMBtnTransparent {
  background: #da291c;
  color: #fff !important;
  text-shadow: none;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
#visBody button.sapMBtn:hover > .sapMBtnHoverable.sapMBtnTransparent span,
#visBody button:not(.dropdown).sapMBtn:hover > .sapMBtnHoverable.sapMBtnTransparent span {
  color: inherit;
}
#visBody button.sapMBtn:hover > .sapMBtnHoverable.sapMToggleBtnPressed:not(.sapMBtnActive),
#visBody button:not(.dropdown).sapMBtn:hover > .sapMBtnHoverable.sapMToggleBtnPressed:not(.sapMBtnActive) {
  background: #da291c;
  color: #fff !important;
  text-shadow: none;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
#visBody button.sapMBtn:hover > .sapMBtnHoverable.sapMToggleBtnPressed:not(.sapMBtnActive) span,
#visBody button:not(.dropdown).sapMBtn:hover > .sapMBtnHoverable.sapMToggleBtnPressed:not(.sapMBtnActive) span {
  color: inherit;
}
#visBody button.sapMBtn:hover > .sapMBtnHoverable .sapMBtnHoverable.sapMToggleBtnPressed:hover,
#visBody button:not(.dropdown).sapMBtn:hover > .sapMBtnHoverable .sapMBtnHoverable.sapMToggleBtnPressed:hover {
  background: #da291c;
  color: #fff !important;
  text-shadow: none;
  box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
}
#visBody button.sapMBtn:hover > .sapMBtnHoverable .sapMBtnHoverable.sapMToggleBtnPressed:hover span,
#visBody button:not(.dropdown).sapMBtn:hover > .sapMBtnHoverable .sapMBtnHoverable.sapMToggleBtnPressed:hover span {
  color: inherit;
}
#visBody button .sapMBtnInner,
#visBody button:not(.dropdown) .sapMBtnInner {
  font-size: 1.125rem;
  background: #da291c;
  border-color: #da291c;
  color: #fff !important;
  text-shadow: none;
  margin: 5px 2px 5px 0;
}
#visBody button .sapMBtnInner span,
#visBody button:not(.dropdown) .sapMBtnInner span {
  color: inherit;
}
#visBody button .sapMBtnInner .sapUiIcon,
#visBody button:not(.dropdown) .sapMBtnInner .sapUiIcon {
  color: #fff;
  background: transparent !important;
}
#visBody .blockForm {
  overflow: visible;
  padding-bottom: 1rem;
  border-bottom: 2px solid #d9e0f2;
}
#visBody .blockForm .sapUiBlockLayoutCell {
  margin: 0 0.5rem;
}
#visBody .blockForm .sapUiBlockCellContent {
  margin-top: 16px;
}
#visBody .blockForm .wHeadline .sapUiBlockLayoutCell {
  margin: 0;
}
#visBody .blockForm .sapUiBlockCellTitle {
  margin-bottom: 0;
}
#visBody .blockForm .sapMSlt {
  margin-top: 1px;
}
#visBody .blockForm .formText {
  font-size: 1rem !important;
  line-height: 33px;
  height: 40px;
  vertical-align: bottom;
}
#visBody .blockForm .sapMRbB .sapMRbBInn {
  margin-top: 1px;
}
#visBody .blockForm .sapMRb {
  clear: none;
  margin: 0.5rem 0.75rem 0.5rem 0;
}
#visBody .blockForm .sapMRb:focus:before {
  width: 100% !important;
  left: 0 !important;
  top: 0.125rem !important;
}
#visBody .blockForm .sapMRb .sapMRbB {
  top: 0;
  height: 2rem;
  width: 2rem;
}
#visBody .blockForm .sapMRb .sapMRbB .sapMRbBOut {
  margin: 0;
  padding-top: 0;
}
#visBody .blockForm .sapMRb .sapMRbBLabel {
  max-width: calc(100% - 2rem + 1px);
}
#visBody .blockForm .sapMRb .sapMRbBLabel {
  margin-left: 0;
  margin-right: .8125rem;
  top: 13px!important;
  left: 3px !important;
  position: relative !important;
}
#visBody .blockForm .sapMRbG .sapMRb:focus:before {
  width: 100% !important;
  left: 0 !important;
  top: 0.125rem !important;
}
#visBody .blockForm .sapMRbG .sapMRb .sapMRbB {
  height: 2rem;
  width: 2rem;
}
#visBody .blockForm .sapMRbG .sapMRb .sapMRbB .sapMRbBOut {
  margin: 0 0.5rem 0 0;
  padding-top: 0 !important;
}
#visBody .blockForm .sapMRbG .sapMRb .sapMRbBLabel {
  padding-left: 2rem;
  max-width: calc(100% + 1px);
  height: 2rem;
  line-height: 2rem;
}
#visBody .blockForm .sapMCb.firstChild {
  padding-left: 35px;
}
#visBody .blockForm .sapMCb.firstChild .sapMCbMark {
  left: 0;
}
@media (max-width: 560px) {
  #visBody .blockForm .sapMCb {
    padding-left: 35px;
  }
  #visBody .blockForm .sapMCb .sapMCbMark {
    left: 0;
  }
}
#visBody .blockForm .sapMCb .sapMLabel {
  cursor: pointer;
}
#visBody .blockForm .sapMDP {
  width: 9rem !important;
}
#visBody .blockForm .sapMLabel {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}
#visBody .blockForm .sapMCbLabel {
  line-height: normal;
}
#visBody .blockForm .sapMLabel:not(.sapMRbBLabel):not(.sapMCbLabel) {
  padding: 0 0 3px 0;
  cursor: pointer;
}
#visBody .blockForm .sapUiHLayout {
  position: relative;
}
#visBody .blockForm .sapUiHLayout .oevbsIco {
  position: absolute;
  top: 0;
  margin: 0 0 0 8px;
}
#visBody .blockForm .oevbsIco {
  margin: -2px 0 0 8px;
}
#visBody .blockForm .oevbsIco.fleft {
  margin-top: 12px;
  margin-left: -8px;
  margin-right: 1rem;
}
#visBody .sapMSF {
  display: inline-block;
}
#visBody .nextBG {
  background: #d9e0f2;
  padding: 1rem 0.5rem;
  font-weight: normal !important;
  font-size: 1.375rem !important;
  line-height: 1.625rem !important;
  color: #3c3c3b !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  #visBody .nextBG {
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
  }
}
#visBody .nextBG h3 {
  font-weight: normal !important;
  font-size: 1.375rem !important;
  line-height: 1.625rem !important;
  color: #3c3c3b !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  #visBody .nextBG h3 {
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
  }
}
#visBody .nextBG .sapMFT {
  display: inline-block;
  font-size: inherit !important;
  font-weight: inherit !important;
}
#visBody .nextBG .sapMBtn {
  margin-top: 0.5rem;
  margin-right: 0 !important;
}
#visBody .nextBG .sapUiBlockLayoutCell,
#visBody .nextBG .sapUiBlockCellContent {
  margin: 0 !important;
}
#visBody .sapMCb {
  margin: 0.5rem 0;
  height: inherit !important;
  line-height: normal !important;
}
#visBody .sapMCb > label {
  text-overflow: clip !important;
  overflow: visible !important;
  white-space: normal !important;
  line-height: normal;
}
#visBody .sapMCb .sapMCbBg {
  top: 0 !important;
}
#visBody .sapMRbBLabel {
  height: inherit !important;
  line-height: normal !important;
}
#visBody .sapMRbBLabel:before {
  display: none;
}
#visBody .noOverflow {
  overflow: visible !important;
}
#visBody .noWhiteSpace {
  white-space: normal !important;
}
#visBody .mw80 {
  min-width: 80px;
}
.sapMInputBaseIcon {
  background: #0d39ab;
}
