.blockSize {
    background:@oevbs_blue;
    color: white;
    //width: ~"calc(100% - 4px)";
    width: 100%;
    margin: 0 auto;
    max-width: 830px;
    min-width: 160px;
}

.lblSettings {
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.5rem;
  vertical-align: top;
  display: block;
  max-width: 100%;
}

.blockWrap {
  width: ~"calc(33% - 20px)";
  min-width: ~"calc(190px - 25px)";
  margin: 0px 10px;

  .sapMLabel {
    .lblSettings !important;
  }
}

.dataFrgmt {
  .blockSize;
   //border: 2px solid @oevbs_blue;
   padding: 10px 0 10px 10px;
   display: flex !important;
   align-items: stretch;
   flex-wrap: wrap;

  .birthdate {
    .blockWrap;
    .sapMInput {
      width: 125px !important;
    }
  }
  .homeSpace {
    .blockWrap;
    .sapMLabel {
      display: inline-block !important;
    }
    .cbHomespace {
      margin: 0 0 0 10px;
      background-color: @oevbs_blue;
      background-image: url('./../img/decline3.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 22px;
      width: 22px;
      display:inline-block;
      cursor: pointer !important;

      &[data-enclosed="X"] {
        background-image: url('./../img/check.png');
      }
    }

    .wrapper {
      width: 100%;
      margin: 0 !important;
      padding: 0 !important;

      .qm {
        width: 55px !important;
        margin: 0 5px 0 0;
      }
      .zip {
        width: 85px !important;
      }
    }
  }

  .stocknumber {
    .blockWrap;
    .sapMSlt, .sapMInput {
      width: 125px !important;
    }
  }

  .term {
    .blockWrap;
  }

  .period {
    .blockWrap;
  }

  .beginn {
    .blockWrap;
  }

  .sapMBtn:hover > .sapMBtnHoverable .sapMBtnIcon {
    box-shadow: none !important;
  }

}

//Begim
//Online
.blockSizeOnline {
  //background:@oevbs_blue;
  color: white;
  //width: ~"calc(100% - 4px)";
  width: ~"calc(100% -1px)";
  margin: 0 auto;
  max-width: 100%;
  min-width: 50%;
}

.lblSettingsOnline {
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.5rem;
  vertical-align: super;
  width: ~"calc(50% - 30px)";
  margin-left: 2px;
}

.blockWrapOnline {
  width: ~"calc(100% - 2px)";
  min-width: ~"calc(100% - 2px)";
  .sapMLabel {
    .lblSettingsOnline !important;
  }
}

//.dataBlockOnline {
.blockPeriodTerm {
  .blockSize;
  //border: 2px solid @oevbs_blue;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;

  .term {
    //.blockWrap;
    //.sapMSlt  {
    width: 135px !important;
    margin: 3px;
    border-color: white;
    //}
    &.sapMSlt > .sapMSltLabel {
      color: white;
      background: @oevbs_blue;
      text-overflow: unset !important;
      width: 120% !important;
    }
    .sapMSltArrow {
      color: white;
    }
  }

  .period {
    //.blockWrap;
    //.sapMSlt {
    width: 135px !important;
    margin: 3px;
    border-color: white;
    //}
    &.sapMSlt>.sapMSltLabel {
      color: white;
      background: @oevbs_blue;
    }
    .sapMSltArrow {
      color: white;
    }
    & .sapMSlt>.sapMSltLabel {
      text-overflow: unset !important;
    }
  }

  .beginn {
    //.blockWrap;
    //.sapMSlt {
    width: 135px !important;
    margin: 3px;
    border-color: white;
    //}
    &.sapMSlt>.sapMSltLabel {
      color: white;
      background: @oevbs_blue;
    }
    .sapMSltArrow {
      color: white;
    }
    & .sapMSlt>.sapMSltLabel {
      text-overflow: unset !important;
    }
  }

}

.blockBegin {
  .blockWrapOnline;
  //border: 2px solid @oevbs_blue;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .sapMLabel {
    width: 172px !important;
  }
  .begin {
    width: 165px !important;
    .sapMInputBaseInner {
      height:37px;
      color: white;
      background: @oevbs_blue;
      border-color: white;
    }
      .sapMInputValHelpInner {
        color: white;
      }
  }
}

.blockBack {
  .blockSize;
  //border: 2px solid @oevbs_blue;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;
  .backBtn {
    height: 40px;
    //width: 135px;
    margin-top: 20px !important;
    margin-right: 3px;
    //position: absolute;
    left: 1px;
    right: 10px;
    bottom: 14px;
    .sapMBtnInner {
      border-color: #fff !important;
      background: @oevbs_blue !important;
      border-radius: 30px;
      color: #fff;
      //font-weight: bold;
      height: 40px;
      vertical-align: middle;
      line-height: 40px;
      font-size: 0.9rem !important;
    }
    .sapMBtnContent {
      height: 40px;
      line-height: 40px;
    }

    &:hover {
      .sapMBtnInner {
        border-color: @oevbs_blue !important;
        background: #fff !important;
        color: @oevbs_blue;
      }
    }
  }
  a.iconPdf {
    font-family: @fontFamily;
    border: 1px solid #fff !important;
    background: @oevbs_blue !important;
    border-radius: 30px;
    color: #fff !important;
    height: 40px;
    vertical-align: middle;
    line-height: 40px;
    font-size: 0.9rem;
    padding: 0 10px;
    margin-top: 11px;
    &:focus {
      border-color: @color_green !important;
    }
    &:hover {
        background: #fff !important;
        color: @oevbs_blue;
        border-color: @color_green !important;
        box-shadow: inset 0 0 0 4rem rgba(0, 0, 0, 0.25);
        text-decoration: none !important;
    }
  }

  .editOffer {
    margin-right: 10px !important;
  }
}


//Online
.questionsFrgmt {
  .blockSizeOnline;
  //border: 2px solid @oevbs_blue;
  //margin: 5px 0 5px 0;f
  padding-bottom: 7px;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;


  .oevbsIco {
    margin-left: 5px;
  }

  .age {
    margin-top: 7px;
    background: @bg_header_gradient_oevbs;
    .blockWrapOnline;
    .ageInp {
      width: 100px !important;
      vertical-align: text-bottom;
      top:10px;
       .sapMSltArrow {
        background: rgba(222,222,222,0.5);
      }
    }
  }

  .status {
    margin-top: 7px;
    background: @bg_header_gradient_oevbs;
    .blockWrapOnline;
    .statusInp {
      width: 100px !important;
      vertical-align: text-bottom;
      top:10px;
      .sapMSltArrow {
        background: rgba(222,222,222,0.5);
      }
    }
  }

  .homeStatus {
    margin-top: 7px;
    background: @bg_header_gradient_oevbs;
    .blockWrapOnline;
    .homeStatusInp {
      width: 101px !important;
      vertical-align: text-bottom;
      top:10px;
      .sapMSltArrow {
        background: rgba(222,222,222,0.5);
      }
    }
  }
  .homeSpace {
    margin-top: 7px;
    background: @bg_header_gradient_oevbs;
    .blockWrapOnline;
    .qmInp {
      width: 60px !important;
      vertical-align: text-bottom;
      top:10px;
    }
  }

  .homeZip {
    margin-top: 7px;
    background: @bg_header_gradient_oevbs;
    .blockWrapOnline;
    .zipInp {
      width: 101px !important;
      vertical-align: text-bottom;
      top:10px;
    }
  }

  .calculateBlock {
    margin-top: 7px;
    background: @bg_header_gradient_oevbs;
    .blockWrapOnline !important;

    position: relative;
    display: block;
    height: 68px;


      .handICO {
        cursor: pointer !important;
        display: inline-block;
        background-image: url('./../img/handBG.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        -webkit-transform: rotate(@rotateDeg);
        -moz-transform: rotate(@rotateDeg);
        -ms-transform: rotate(@rotateDeg);
        -o-transform: rotate(@rotateDeg);
        transform: rotate(@rotateDeg);

        margin-left: 5px;
        margin-top: 5px;
        width: 60px;
        height: 60px;
        &:hover {
          .shake;
        }
      }

    .nextBtn {
      height: 60px;
      margin-top: 10px;
      position: absolute;
      right: 10px;
      bottom: 5px;
      .sapMBtnInner {
        border-color: #fff !important;
        background:  @oevbs_blue !important;
        border-radius: 30px;
        color: #fff;
        font-weight: bold;
        height: 50px;
        vertical-align: middle;
        line-height: 50px;
        font-size: 1.3rem;
      }
      .sapMBtnContent {
        height: 50px;
        line-height: 50px;
      }

      &:hover {
        .sapMBtnInner {
          border-color: @oevbs_blue !important;
          background: #fff !important;
          color: @btn_default_red;
        }
      }
      &:focus {
        .sapMBtnInner {
          border-color: @color_green !important;
        }
      }
    }
  }

  .fadeIn {
    display: inline-block;
    opacity: 0;
    -webkit-animation: fade 0.3s ease-in 0.3s forwards;
    animation: fade 0.3s ease-in 0.3s forwards;
  }

  .sapMSltWithArrow > .sapMSltLabel, .sapMSltWithArrow .sapMSelectListItemBase {
    padding: 0 0 0 0.3rem !important;
  }

  .sapMInputBaseInner {
    color: #5cb85c;
  }

  .sapMSlt > .sapMSltLabel {
    color: #5cb85c !important;
  }

  .sapMInputBaseInner:not(.sapMInputBaseReadonlyInner):not(.sapMInputBaseStateErrorInner):not(.sapMInputBaseDisabledInner):focus {
    background: #fafafa;
  }


}//Ende Online

