.sapUiBlockLayoutSizeXL {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}
.sapUiBlockLayoutSizeL {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}
.sapUiBlockLayoutSizeM {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}
.sapUiBlockLayoutSizeS {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}
.sapUiBlockLayoutSizeXS {
  .sapUiBlockCellContent {
    padding: 0 !important;
  }
}

.sapUiBlockLayoutSizeXL {
  .sapUiBlockCellContent > .sapMText {

  }
}

  .sapUiBlockCellTitle {
    .h3 !important;
  }

  .sapUiBlockLayout  {
    margin-bottom: 1rem;
  }
