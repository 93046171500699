.sendFrgmt {
  overflow: visible;
  padding: 1rem;
  background-color: @c_5;
  margin: 1rem 0.5rem;

  .sapUiBlockLayoutCell {
    h2 {
      margin-bottom: 0.5rem;
      font-size: 2.25rem !important;
      color: @oevbs_blue !important;
      line-height: 2.625rem !important;
      min-height: 100px;
      vertical-align: middle;
      padding-top: 0.5rem;
      padding-left: 110px;
      width: ~"calc(100% - 110px)";
      background-repeat: no-repeat;
      background-size: 100px 100px;
      background-position: 0 0;
      background-position-y: -10px;
    }

    &.police {
      h2 {
        background-image: url('../img/police.png');
      }
    }
  }

  .errMsg {
    margin-top: 1rem;
  }

  .sapMCb {
    padding: 0 0 0 2rem;
    .sapMCbBg {
      left: 0;
    }
  }

  .floatLeft {
    float: left !important;
  }

  .sapMBtn {
    float: right;
  }

  .handICO {
    cursor: pointer !important;
    display: inline-block;
    background-image: url('./../img/handBG.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    -webkit-transform: rotate(@rotateDeg);
    -moz-transform: rotate(@rotateDeg);
    -ms-transform: rotate(@rotateDeg);
    -o-transform: rotate(@rotateDeg);
    transform: rotate(@rotateDeg);

    margin-left: 5px;
    margin-top: 5px;
    width: 60px;
    height: 60px;

    &:hover {
      .shake;
    }
  }
}