.thanksFrgmt {
  overflow: visible;
  padding: 1rem;
  background-color: @c_5;
  margin: 1rem 0.5rem;


  .sapUiBlockLayoutCell {
    h2 {
      margin-bottom: 0.5rem;
      font-size: 2.25rem !important;
      color: @oevbs_blue !important;
      line-height: 2.625rem !important;
      min-height: 100px;
      vertical-align: middle;
      padding-top: 0.5rem;
      padding-left: 110px;
      width: ~"calc(100% - 110px)";
      background-repeat: no-repeat;
      background-size: 100px 100px;
      background-position: 0 0;
      background-position-y: -10px;
      background-image: url('../img/success.png');
    }
  }


  .sapMCb {
    padding: 0 0 0 2rem;
    .sapMCbBg {
      left: 0;
    }
  }

  .sapMBtn {
    float: left;
  }
}