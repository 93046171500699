@import "dataFrgmt";
@import "productsFrgmt";
@import "priceFrgmt";

.policyholderTray {
  width: 100%;
  .tray {
    width:100%;
    max-width: 840px;
    min-width: 320px;
  }
}

@media (min-width: 1080px) {
  .dataFrgmt {
    display: inline-block !important;
    margin: 0 20px 0 5px;
    width: 185px;
    position: fixed;
    left: 0;
    //top: 125px; //VIS und Online!
    padding: 10px;
  }

  .blsk {
    .dataFrgmt {
      position: absolute;
      top: 0;
      width: 180px;
    }
  }

  .productsFrgmt{ //auch vis
    margin: 0 0 0 210px;
    float: left;
    width: 840px;
  }
  .policyholderTray{ //auch vis
    margin: 0 0 0 210px;
    float: left;
  }

  .blockPrice {
    margin: 0 0 0 210px !important;
    width: 840px;
  }

  //Online
  .questionsFrgmt {
    margin: 0 20px -15px 0px;
    width: 50%;
    left: 0;
  }

  .blockBtn {
    .blockSizeOnline;
    // width: 50%;
    //max-width: 940px;
    //min-width: 340px;
    height: 68px;
    position: absolute !important;
    display: block !important;
    //background: @oevbs_blue;
  }

}

.blockBtn {
  width: ~"calc(50% - 2px)";
  max-width: 840px;
  min-width: 335px;
  height: 68px;
  position: relative;
  display: block !important;
  background: @oevbs_blue;

  .nextBtn {
    height: 60px;
    margin-top: 10px;
    position: absolute;
    //right: 50px;
    right: 15px;
    //top: -5px;
    .sapMBtnInner {
      border-color: #fff !important;
      background: @oevbs_blue !important;
      border-radius: 30px;
      color: #fff;
      font-weight: bold;
      height: 50px;
      vertical-align: middle;
      line-height: 50px;
      font-size: 1.3rem;
    }
    .sapMBtnContent {
      height: 50px;
      line-height: 50px;
    }

    &:hover {
      .sapMBtnInner {
        border-color: @oevbs_blue !important;
        background: #fff !important;
        color: @btn_default_red;
      }
    }

    &:focus {
      .sapMBtnInner {
        border-color: @color_green !important;
      }
    }
  }

  .handICO {
    //cursor: pointer !important;
    display: inline-block;
    background-image: url('./../img/handBG.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    margin-left: 15px;
    margin-top: 5px;
    width: 60px;
    height: 60px;

    &:hover {
      .shake;
    }
  }
}


