.btnBlue {
  background: @color_blue;
  border-color: @color_blue;
  color: @color;
  text-shadow: none;
  span {
    color: inherit;
  }
  .sapUiIcon {
    color: @color;
  }
}

.btnRed {
  background: @btn_default_red;
  border-color: @btn_default_red;
  color: @color !important;
  text-shadow: none;
  margin: 5px 2px 5px 0;
  span {
    color: inherit;
  }
  .sapUiIcon {
    color: @color;
    background: transparent !important;
    //padding: 8px 0 0 12px;
  }
}

.btnRedHover {
  background: @btn_default_red_hover;
  color: @color !important;
  text-shadow: none;
  box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
  span {
    color: inherit;
  }
}

.btnGrey {
  background: @c_5;
  border-color: @c_14;
  color: @color_darkgrey !important;
  text-shadow: none;
  span {
    color: inherit;
  }
}

.btnGreyHover {
  background: @c_5;
  text-shadow: none;
  color: @color_darkgrey !important;
  box-shadow: inset 0 0 0 4rem rgba(0,0,0,0.25);
  span {
    color: inherit;
  }
}

.toggleBtnPressed {
  background: @btn_default_red;
  border-color: @btn_default_red;
  color: @color;
  text-shadow: none;
}

.toggleBtnPressedBlue {
  background: @bg_blue_gradient;
  border-color: @color_blue;
  color: @color;
  text-shadow: none;
}

.body {
  background: #fff;
  color: @color_greyblack;
  padding: 0;
  margin: 0;
  font-family: @fontFamily !important;
  font-weight: 200;
  font-style: normal;
  line-height: 1.25rem;
  position: relative;
  cursor: default;
  width: 100%;//Online
  /* overflow-y: overlay; 2.Balken halb verstecken */
}

.fontProperties {
  font-family: @fontFamily !important;
  font-size: 1rem;
  color: @color_greyblack;
}

.blockHeaderBg {
  background: @bg_header_gradient;
  background-size: 100% 55px;
  background-repeat: no-repeat;

  .sapUiBlockCellTitle {
    &.sapUiBlockCellBegin {
      color: @color;
      margin-bottom: 1.5rem;
    }
  }
}

.red {
  color: @color_red !important;
}
.green {
  color: @color_green !important;
}

.headline {
  font-size: 2.25rem;
  line-height: 2.625rem;
  color: @oevbs_blue;
  margin-bottom: 0.938rem;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 1.75rem !important;
    line-height: 1.75rem !important;
  }
}

.h3 {
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 1.625rem;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
  }

  color: @color_greyblack;
}