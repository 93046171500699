@rotateDeg: -15deg;
@txtRotateDeg: -8deg;

.priceFrgmt {
  display:inline-block;
  width: 100%;

  .priceHand {
    display: none;
  }

  .blockPrice {
    margin: 0 auto;
    width:100%;
    max-width: 840px;
    min-width: 320px;
    display:block;
    height: 68px;
    position: relative;
    background: @oevbs_blue;

    .handICO {
      cursor: pointer !important;
      display: inline-block;
      background-image: url('./../img/handBG.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      -webkit-transform: rotate(@rotateDeg);
      -moz-transform: rotate(@rotateDeg);
      -ms-transform: rotate(@rotateDeg);
      -o-transform: rotate(@rotateDeg);
      transform: rotate(@rotateDeg);

      margin-left: 5px;
      margin-top: 5px;
      width: 60px;
      height: 60px;

      &:hover {
        .shake;
      }
    }



    .priceBlock {
      position: absolute;
      left: 185px;
      top: 10px;
      line-height: 2rem;
      display: inline-block;

      strong {
        color: #fff;
        font-size: 1.75rem;
        display: block;
      }

      span {
        color: #fff;
        font-size: 1.5rem;
        display: block;
        span {
          text-decoration: line-through;
          display: inline;
        }
      }
    }

    .priceBlockOnline {
      position: absolute;
      left: 75px;
      top: 10px;
      line-height: 2rem;
      display: inline-block;

      strong {
        color: #fff !important;
        font-size: 1.75rem;
        display: block;
      }

      span {
        color: #fff !important;
        font-size: 1.5rem;
        display: block;
        span {
          text-decoration: line-through;
          display: inline;
        }
      }
    }
    .nextBtn {
      height: 60px;
      margin-top: 10px;
      position: absolute;
      right: 10px;
      bottom: 5px;
      .sapMBtnInner {
        border-color: #fff !important;
        background:  @oevbs_blue !important;
        border-radius: 30px;
        color: #fff;
        font-weight: bold;
        height: 50px;
        vertical-align: middle;
        line-height: 50px;
        font-size: 1.3rem;
      }
      .sapMBtnContent {
        height: 50px;
        line-height: 50px;
      }

      &:hover {
        .sapMBtnInner {
          border-color: @oevbs_blue !important;
          background: #fff !important;
          color: @btn_default_red;
        }
      }
      &:focus {
        .sapMBtnInner {
          border-color: @color_green !important;
        }
      }
    }

  }

  @media(min-width: 880px) {
    .priceHand {
        display: inline-block;
        background-image: url('./../img/handBG.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        -webkit-transform: rotate(@rotateDeg);
        -moz-transform: rotate(@rotateDeg);
        -ms-transform: rotate(@rotateDeg);
        -o-transform: rotate(@rotateDeg);
        transform: rotate(@rotateDeg);

        margin-left: 20px;
        width: 150px;
        height: 150px;

        position: fixed;

        strong {
          font-size: 1rem;
          position: absolute;
          left: 36px;
          bottom: 20px;
          -webkit-transform: rotate(@txtRotateDeg);
          -moz-transform: rotate(@txtRotateDeg);
          -ms-transform: rotate(@txtRotateDeg);
          -o-transform: rotate(@txtRotateDeg);
          transform: rotate(@txtRotateDeg);
          color: @oevbs_blue;
        }
        span {
          font-size: 1rem;
          text-decoration: line-through ;
          color: @oevbs_red;
          position: absolute;
          top: 80px;
          left: 35px;
          -webkit-transform: rotate(@rotateDeg);
          -moz-transform: rotate(@rotateDeg);
          -ms-transform: rotate(@rotateDeg);
          -o-transform: rotate(@rotateDeg);
          transform: rotate(@rotateDeg);
        }
    }

  }

}

